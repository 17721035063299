import React from "react";
import "./css/CancellationRefundPolicy.css"; // Import the CSS file for styling
import { Footer, Navbar } from "../components"; // Ensure this component is imported correctly

const CancellationRefundPolicy = () => {
    return (
        <>
            <Navbar />
            <div className="cancellation-refund-policy">
                <div className="container">
                    <h1 className="mt-5">Cancellation & Refund Policy</h1>

                    <section>
                        <h2>Order Cancellation</h2>
                        <p>
                            Orders can be canceled before they are shipped. If you wish to cancel your order, please contact us immediately. Once the order is shipped, it cannot be canceled.
                        </p>
                    </section>

                    <section>
                        <h2>Returns</h2>
                        <p>
                            We offer a 14-day return policy from the date of delivery. To be eligible for a return, the item must be unused and in the same condition that you received it. It must also be in the original packaging.
                        </p>
                    </section>

                    <section>
                        <h2>Refunds</h2>
                        <p>
                            Once we receive your return, we will inspect the item and notify you of the status of your refund. If approved, your refund will be processed to your original method of payment within 7-10 business days.
                        </p>
                    </section>

                    <section>
                        <h2>Non-Refundable Items</h2>
                        <p>
                            Certain items are non-refundable, such as perishable goods, custom products, and items on sale. Please check the product description for specific details.
                        </p>
                    </section>

                    <section>
                        <h2>Exchange Policy</h2>
                        <p>
                            If you need to exchange an item for a different size or color, please contact us within 14 days of receiving your order. The new item will be shipped after we receive the returned item in its original condition.
                        </p>
                    </section>

                    <section>
                        <h2>Contact Us</h2>
                        <p>
                            If you have any questions or concerns about our Cancellation & Refund Policy, please contact us at:
                        </p>
                        <p>Email: support@surplusdepot.com</p>
                        <p>Phone: +91 9115111844</p>
                    </section>
                </div>
            </div>
            <Footer />
        </>

    );
};

export default CancellationRefundPolicy;