import { Navbar, Main,Categories, Product, Footer,ImageScroller } from "../components";

function Home() {
  return (
    <>
      <Navbar />
      {/* <Main /> */}
      <ImageScroller />
      <Categories />

      <Product />
      <Footer />
    </>
  )
}

export default Home