import React from "react";
import Slider from "react-slick";
import "./css/imageScroller.css"; // Import your custom CSS file

const ImageScroller = () => {
  const settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 2000,
    arrows: false,
  };

  const data = [
    {
      id: '1',
      imageUrl: 'https://berlinfasteners.com/images/banner1.jpeg',
    },
    {
      id: '2',
      imageUrl: 'https://berlinfasteners.com/images/banner4.jpeg',
    },
    {
      id: '3',
      imageUrl: 'https://storage.googleapis.com/supertails-test/Untitled%20design.png-1724056984104',
    },
  ];

  return (
    <div className="image-scroller-container">
      <Slider {...settings}>
        {data.map((item) => (
          <div key={item.id} className="image-scroller-item">
            <img src={item.imageUrl} alt={`Slide ${item.id}`} className="image-scroller-image" />
          </div>
        ))}
      </Slider>
    </div>
  );
};

export default ImageScroller;