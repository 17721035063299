import React from "react";
import "./css/PrivacyPolicy.css"; // Import the CSS file for styling
import { Footer,Navbar } from "../components"; // Ensure this component is imported correctly

const PrivacyPolicy = () => {
  return (
    <>
    <Navbar />
    <div className="privacy-policy">
      <div className="container">
        <h1 className="mt-5">Privacy Policy</h1>
        <p className="text-muted">
          Last updated: September 12, 2024
        </p>
        
        <section>
          <h2>Introduction</h2>
          <p>
            Welcome to Surplus Depot. Your privacy is important to us. This Privacy Policy explains how we collect, use, disclose, and safeguard your information when you visit our website. Please read this policy carefully to understand our practices regarding your personal information.
          </p>
        </section>

        <section>
          <h2>Information We Collect</h2>
          <p>We may collect the following types of information:</p>
          <ul>
            <li>
              <strong>Personal Information:</strong> Name, email address, phone number, and shipping address when you create an account, place an order, or contact us.
            </li>
            <li>
              <strong>Payment Information:</strong> Payment details are collected through our secure payment processing partners.
            </li>
            <li>
              <strong>Usage Information:</strong> Details of your visits to our website, including traffic data, location data, and other communication data.
            </li>
            <li>
              <strong>Cookies and Tracking Technologies:</strong> Information collected via cookies, web beacons, and other tracking technologies.
            </li>
          </ul>
        </section>

        <section>
          <h2>How We Use Your Information</h2>
          <p>We use the information we collect for the following purposes:</p>
          <ul>
            <li>To provide, operate, and maintain our website and services.</li>
            <li>To process your orders, manage your account, and provide customer support.</li>
            <li>To improve our website, services, and user experience.</li>
            <li>To send you promotional information, newsletters, and updates.</li>
            <li>To comply with legal obligations and protect our rights and interests.</li>
          </ul>
        </section>

        <section>
          <h2>Sharing Your Information</h2>
          <p>
            We do not share your personal information with third parties except in the following cases:
          </p>
          <ul>
            <li>To our trusted service providers who assist us in operating our website and conducting our business.</li>
            <li>To comply with a legal obligation or protect the rights, property, or safety of our company, our customers, or others.</li>
            <li>To any successors in interest to our business, such as a merger, sale, or acquisition.</li>
          </ul>
        </section>

        <section>
          <h2>Cookies and Tracking Technologies</h2>
          <p>
            We use cookies and similar tracking technologies to enhance your experience on our website. You can manage your cookie preferences through your browser settings. By using our website, you consent to our use of cookies as described in this policy.
          </p>
        </section>

        <section>
          <h2>Your Rights and Choices</h2>
          <p>You have the following rights regarding your personal information:</p>
          <ul>
            <li>Access: You have the right to request access to the personal information we hold about you.</li>
            <li>Correction: You can request that we correct any inaccuracies in your personal information.</li>
            <li>Deletion: You can request that we delete your personal information, subject to certain conditions.</li>
            <li>Opt-Out: You can opt out of receiving marketing communications from us at any time.</li>
          </ul>
        </section>

        <section>
          <h2>Data Security</h2>
          <p>
            We implement appropriate security measures to protect your personal information from unauthorized access, disclosure, alteration, or destruction. However, no method of transmission over the Internet or electronic storage is 100% secure.
          </p>
        </section>

        <section>
          <h2>Changes to This Privacy Policy</h2>
          <p>
            We may update this Privacy Policy from time to time. We will notify you of any changes by posting the new Privacy Policy on this page. You are advised to review this Privacy Policy periodically for any changes.
          </p>
        </section>

        <section>
          <h2>Contact Us</h2>
          <p>
            If you have any questions or concerns about this Privacy Policy, please contact us at:
          </p>
          <p>Email: support@surplusdepot.com</p>
          <p>Phone: +91 9115111844</p>
        </section>
      </div>
    </div>
    <Footer />
    </>

  );
};

export default PrivacyPolicy;