import React, { useState, useEffect } from 'react';


const BidBox = ({ isModal = false, bidEndTime }) => {
  const [timeLeft, setTimeLeft] = useState('');

  useEffect(() => {
    console.log("BidTimer",bidEndTime);
    const calculateTimeLeft = () => {
      const now = new Date();
      const endTime = new Date(bidEndTime);
      const difference = endTime - now;
      let timeLeftString = '';

      if (difference > 0) {
        const days = Math.floor(difference / (1000 * 60 * 60 * 24));
        const hours = Math.floor((difference / (1000 * 60 * 60)) % 24);
        const minutes = Math.floor((difference / 1000 / 60) % 60);
        const seconds = Math.floor((difference / 1000) % 60);

        timeLeftString = `${String(days).padStart(2, '0')}:${String(hours).padStart(2, '0')}:${String(minutes).padStart(2, '0')}:${String(seconds).padStart(2, '0')}`;
      } else {
        timeLeftString = '00:00:00:00';
      }

      setTimeLeft(timeLeftString);
    };

    calculateTimeLeft();
    const timerId = setInterval(calculateTimeLeft, 1000);
    return () => clearInterval(timerId);
  }, [bidEndTime]);

  const containerStyle = {
    backgroundColor: '#FFE4D0',
    display: 'flex',
    alignItems: 'center',
    borderWidth: '1px',
    border: '1.5px solid #FF4107',
    borderRadius: '20px',
    padding: '4px 8px',
    width: 'fit-content',
    margin: isModal ? '0' : 'auto',
  };

  const timeoutStyle = {
    display: 'flex',
    alignItems: 'center',
  };

  const timeLeftStyle = {
    display: 'flex',
    alignItems: 'center',
  };

  const timerTextStyle = {
    fontSize: '14px',
    marginLeft: '6px',
    marginRight: '4px',
  };

  const timeStyle = {
    fontSize: '14px',
    color: '#FF4107',
    minWidth: '60px',
  };

  return (
    <div style={containerStyle}>
      {timeLeft === '00:00:00:00' ? (
        <div style={timeoutStyle}>
          <img src='../assets/warning.png' height={24} width={24} />
          <span style={timeStyle}>Bid Timeout</span>
        </div>
      ) : (
        <div style={timeLeftStyle}>
          <img src='../assets/clock.png' height={24} width={24} />
          <span style={timerTextStyle}>Time Left to bid</span>
          <span style={timeStyle}>{" "}{timeLeft}</span>
        </div>
      )}
    </div>
  );
};

export default BidBox;