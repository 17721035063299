import React, { useState, useEffect, useRef } from 'react';
import { useNavigate, useLocation } from 'react-router-dom'; // Use React Router for navigation
import html2canvas from 'html2canvas'; // Import html2canvas for screenshot functionality

function Success(props) {
    const navigate = useNavigate();
    const location = useLocation();
    const invoiceRef = useRef(null); // Ref for invoice screenshot
    const [invoiceData, setInvoiceDataState] = useState(null); // Initialize as null
    const [showInvoice, setShowInvoiceState] = useState(false); // State to show or hide the invoice
    const [isMobile, setIsMobile] = useState(window.innerWidth <= 768); // Default mobile check

    useEffect(() => {
        // Get invoice data from the location state
        if (location.state?.InvoiceData) {
            setInvoiceDataState(location.state.InvoiceData);
        }
    }, [location.state]);

    const captureScreenshot = () => {
        if (invoiceRef.current) {
            html2canvas(invoiceRef.current).then((canvas) => {
                const dataUrl = canvas.toDataURL('image/png');
                const link = document.createElement('a');
                link.href = dataUrl;
                link.download = 'invoice.png';
                link.click();
            });
        }
    };

    const confirmedBidValue = location.state?.confirmedBidValue ?? 500; // Access the confirmed bid value

    // Determine image size based on screen width
    const screenWidth = window.innerWidth;
    const imageSize = screenWidth > 600 ? '40%' : '80%';

    return (
        <div style={{ minHeight:'100vh',display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center', backgroundColor: '#FF4107', width: '100%', paddingTop: '100px',paddingBottom: '100px' }}>
            {/* Display Success Image */}
            <img
                style={{ width: imageSize, height: screenWidth > 600 ? '30%' : '15%', marginBottom: '40px' }}
                src={'./assets/accept.png'}
                alt="Success"
            />
            {/* Payment Complete Text */}
            <p style={{ color: "#fff", fontSize: '30px', fontWeight: 'bold', marginBottom: '20px' }}>₹{confirmedBidValue}</p>
            <p style={{ color: "#fff", fontSize: '18px' }}>Your Payment was successful.</p>
            {invoiceData.paymentReason == "Registration Fee" ? <>

            </> : <>
                <p style={{ color: "#fff", fontSize: '18px' }}>Please check the delivery status at</p>
                <p style={{ color: "#fff", fontSize: '18px' }}>Order Tracking Page</p>
            </>
            }
            {/* Show Invoice Button */}
            <button
                style={{ padding: '18px 0', backgroundColor: '#FF4107', marginTop: '20px', border: 'none', cursor: 'pointer' }}
                onClick={() => setShowInvoiceState(!showInvoice)} // Toggle the invoice display
            >
                <span style={{ textDecoration:'underline',color: "#FFF", fontSize: '18px', textAlign: 'center', fontWeight: '600' }}>{showInvoice ? "Hide Invoice" :'Show Invoice'}</span>
            </button>
            {/* Continue Shopping Button */}
            <button
                style={{ padding: '18px 0', backgroundColor: '#fff', width: '90%', marginTop: '20px', border: 'none', cursor: 'pointer' }}
                onClick={() =>{
                    !invoiceData.paymentReason == "Registration Fee" ?
                    navigate('/main')
                :navigate('/waiting')
                }}
            >
                <span style={{ color: "#FF4107", fontSize: '18px', textAlign: 'center', fontWeight: '600' }}> {invoiceData.paymentReason == "Registration Fee" ? "Continue" : "Continue Shopping"}</span>
            </button>


            {/* Conditional Invoice Display */}
            {showInvoice && invoiceData && (
                isMobile ?
                    <div ref={invoiceRef} style={{ border: '1px solid #ccc', padding: '20px', marginTop: '20px', marginBottom: '70px', width: '90%', maxWidth: '800px', backgroundColor: '#fff' }}>
                        <div style={{ textAlign: 'center', marginBottom: '20px' }}>
                            <h2 style={{ margin: '0', padding: '0' }}>Invoice</h2>
                            <div style={{ textAlign: 'center', marginBottom: '20px' }}>
                                <img src="./assets/icon.png" alt="Company Logo" style={{ width: '150px', maxWidth: '100%' }} />
                            </div>
                            <p style={{ margin: '5px 0' }}>Office No. 727, 7TH Floor, Tower T3, Amrapalli Leisure Valley Tech Zone IV,<br />
                                Greater Noida West, Uttar Pradesh - 201308</p>
                            <p style={{ margin: '5px 0' }}>Email: support@surplusdepot.com, Phone: +91 9115111844</p>
                        </div>
                        <div style={{ display: 'flex', flexDirection: 'column', marginBottom: '20px' }}>
                            <div style={{ flex: 1, marginBottom: '10px' }}>
                                <p><strong>Invoice Number:</strong> {Math.floor(Math.random() * 100000)}</p>
                                <p><strong>GST Number: </strong>03AALCC9782D1ZI</p>
                            </div>
                            <div style={{ flex: 1 }}>
                                <p><strong>Invoice Date:</strong> {invoiceData.date}</p>
                                <p><strong>Customer Name:</strong> {invoiceData.customerName}</p>
                            </div>
                        </div>
                        <table style={{ width: '100%', borderCollapse: 'collapse', marginBottom: '20px' }}>
                            <thead>
                                <tr>
                                    <th style={{ border: '1px solid #ccc', padding: '10px' }}>Description</th>
                                    <th style={{ border: '1px solid #ccc', padding: '10px' }}>Quantity</th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr>
                                    <td style={{ border: '1px solid #ccc', padding: '10px' }}>{invoiceData.paymentReason ?? "Signup Fee"}</td>
                                    <td style={{ border: '1px solid #ccc', padding: '10px' }}> - </td>
                                </tr>
                                <tr>
                                    <th style={{ border: '1px solid #ccc', padding: '10px' }}>Unit Price</th>
                                    <th style={{ border: '1px solid #ccc', padding: '10px' }}>Total</th>

                                </tr>
                                <tr>
                                    <td style={{ border: '1px solid #ccc', padding: '10px' }}>₹500.00</td>
                                    <td style={{ border: '1px solid #ccc', padding: '10px' }}>₹500.00</td>
                                </tr>
                                {/* Add more rows if needed */}
                            </tbody>
                        </table>
                        <div style={{ textAlign: 'right', marginBottom: '20px' }}>
                            <p><strong>Total Amount:</strong> ₹{invoiceData.total}</p>
                        </div>
                        <div style={{ textAlign: 'center' }}>
                            <button onClick={captureScreenshot} style={{ padding: '10px 20px', backgroundColor: '#FF4107', color: '#fff', border: 'none', cursor: 'pointer' }}>Download Invoice</button>
                        </div>
                    </div>
                    :
                    <div className="invoice" ref={invoiceRef} style={{ border: '1px solid #ccc', padding: '20px', marginTop: '20px', marginBottom: '70px', width: '60%', backgroundColor: '#fff' }}>
                        {/* Invoice details */}
                        <div style={{ textAlign: 'center', marginBottom: '20px' }}>
                            <h2 style={{ margin: '0', padding: '0' }}>Invoice</h2>
                            <div style={{ textAlign: 'center', marginBottom: '20px' }}>
                                <img src="./assets/icon.png" alt="Company Logo" style={{ width: '200px' }} /> {/* Update the path to your logo */}
                            </div>
                            <p style={{ margin: '5px 0' }}>Office No. 727, 7TH Floor, Tower T3, Amrapalli Leisure Valley Tech Zone IV,<br />
                                Greater Noida West, Uttar Pradesh - 201308</p>
                            <p style={{ margin: '5px 0' }}>Email: support@surplusdepot.com,  Phone: +91 9115111844</p>
                        </div>
                        {/* Invoice Data */}
                        <div style={{ display: 'flex', justifyContent: 'space-between', marginBottom: '20px' }}>
                            <div>
                                <p><strong>Invoice Number:</strong> {Math.floor(Math.random() * 100000)}</p>
                                <p><strong>GST Number: </strong>03AALCC9782D1ZI</p>
                            </div>
                            <div>
                                <p><strong>Invoice Date:</strong> {invoiceData.date}</p>
                                <p><strong>Customer Name:</strong> {invoiceData.customerName}</p>

                            </div>
                        </div>
                        <table style={{ width: '100%', borderCollapse: 'collapse', marginBottom: '20px' }}>
                            <thead>
                                <tr>
                                    <th style={{ border: '1px solid #ccc', padding: '10px' }}>Description</th>
                                    <th style={{ border: '1px solid #ccc', padding: '10px' }}>Quantity</th>
                                    <th style={{ border: '1px solid #ccc', padding: '10px' }}>Unit Price</th>
                                    <th style={{ border: '1px solid #ccc', padding: '10px' }}>Total</th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr>
                                    <td style={{ border: '1px solid #ccc', padding: '10px' }}>{invoiceData.paymentReason ?? "Signup Fee"}</td>
                                    <td style={{ border: '1px solid #ccc', padding: '10px' }}> - </td>
                                    <td style={{ border: '1px solid #ccc', padding: '10px' }}>₹500.00</td>
                                    <td style={{ border: '1px solid #ccc', padding: '10px' }}>₹500.00</td>
                                </tr>
                                {/* Add more rows if needed */}
                            </tbody>
                        </table>
                        <div style={{ textAlign: 'right', marginBottom: '20px' }}>
                            <p><strong>Total Amount:</strong> ₹{invoiceData.total}</p>
                        </div>
                        <div style={{ textAlign: 'center' }}>
                            <button onClick={captureScreenshot} style={{ padding: '10px 20px', backgroundColor: '#FF4107', color: '#fff', border: 'none', cursor: 'pointer' }}>Download Invoice</button>
                        </div>
                    </div>
            )}
        </div>
    );
}

export default Success;