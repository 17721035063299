import React, { useState, useEffect } from "react";
import { Link, useNavigate } from "react-router-dom";
import { Footer } from "../components"; // Ensure these components are imported correctly
import "./css/login.css"; // Import the CSS file for styles

const Login = () => {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [snackbarMessage, setSnackbarMessage] = useState("");
  const [snackbarVisible, setSnackbarVisible] = useState(false);
  const navigate = useNavigate(); // useNavigate hook for navigation

  // Auto-hide snackbar after 3 seconds
  useEffect(() => {
    if (snackbarVisible) {
      const timer = setTimeout(() => {
        setSnackbarVisible(false);
      }, 3000); // 3 seconds

      return () => clearTimeout(timer); // Clear timeout if the component unmounts or snackbarVisible changes
    }
  }, [snackbarVisible]);

  const validateEmail = (email) => {
    const re = /\S+@\S+\.\S+/;
    return re.test(email);
  };

  const handleLogin = async (e) => {
    e.preventDefault(); // Prevent page refresh
    if (!validateEmail(email)) {
      setSnackbarMessage("Please enter a valid email address.");
      setSnackbarVisible(true);
      return;
    }
    if (password.length < 6) {
      setSnackbarMessage("Password must be at least 6 characters long.");
      setSnackbarVisible(true);
      return;
    }
    try {
      const response = await fetch(
        `https://boxwood-spot-435312-q8.el.r.appspot.com/user/auth?email=${encodeURIComponent(
          email
        )}&password=${encodeURIComponent(password)}`
      );
      const data = await response.json();
      if (data?.response) {
        localStorage.setItem("userData", JSON.stringify(data?.response?.uuid));
        localStorage.setItem(
          "userDataObject",
          JSON.stringify({
            name: data?.response?.name,
            xName: data?.response?.xName,
            email: data?.response?.email,
            phone: data?.response?.phone,
            access: data?.response?.type,
            status: data?.response?.status,
          })
        );
        setSnackbarMessage("Login Successful");
        setSnackbarVisible(true);
        if (data?.response?.status === "approved") {
          navigate("/home");
        } else {
          navigate("/waiting");
        }
      } else {
        setSnackbarMessage("Login Failed adasda");
        setSnackbarVisible(true);
      }
    } catch (error) {
      console.error("Error during login:", error);
      alert("Login Failed", `Something went wrong during login. ${error}`);
    }
  };

  return (
    <>
      <div className="login-container">
        {snackbarVisible && (
          <div className="snackbar">
            {snackbarMessage}
            <button onClick={() => setSnackbarVisible(false)}>✖</button>
          </div>
        )}
        <div className="login-content">
          <img className="login-logo" src="./assets/icon.png" alt="Logo" />
          <form onSubmit={handleLogin} className="login-form">
            <div className="input-group">
              <label>Email</label>
              <input
                type="email"
                value={email}
                onChange={(e) => setEmail(e.target.value)}
                className="login-input"
                placeholder="name@example.com"
              />
            </div>
            <div className="input-group">
              <label>Password</label>
              <input
                type="password"
                value={password}
                onChange={(e) => setPassword(e.target.value)}
                className="login-input"
                placeholder="Password"
              />
            </div>
            <p className="forgot-password">Forgot Password?</p>
            <button className="login-button" type="submit">
              Login
            </button>
            <p className="register-link">
              Don't have an account?{" "}
              <Link to="/register" className="register-now">
                Register Now
              </Link>
            </p>
          </form>
        </div>
      </div>
      <Footer />
    </>
  );
};

export default Login;