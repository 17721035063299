import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { Footer,Navbar } from "../components"; // Ensure this component is imported correctly
import "./css/waitingPage.css"; // Import the CSS file for styles

const WaitingScreen = () => {
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState("");
  const navigate = useNavigate(); // useNavigate hook for navigation

  useEffect(() => {
    const checkUserData = async () => {
      try {
        const userUUID = JSON.parse(localStorage.getItem("userData") ?? "{}");
        if (userUUID) {
          const response = await fetch(`https://boxwood-spot-435312-q8.el.r.appspot.com/user/${userUUID}`);
          const data = await response.json();
          console.log("data?.response");
          console.log(data);
          if (data?.response) {
            const userData = data?.response;
            if (userData?.status === "approved") {
              navigate("/home");
            }
          }
        } else {
          navigate("/Login");
        }
      } catch (error) {
        console.error("Error checking user data:", error);
        setError("An error occurred while checking user data.");
      } finally {
        setLoading(false);
      }
    };

    setTimeout(checkUserData, 1000); // Wait for 1 second before checking user data
  }, [navigate]);

  return (
    <>
      <div className="waiting-container">
        {loading ? (
          <div className="loading-screen">
            <img className="waiting-logo" src="./assets/icon.png" alt="Logo" />
            <div className="spinner"></div>
          </div>
        ) : (
          <div className="waiting-content">
            {error ? (
              <div className="error-message">
                <p>{error}</p>
                <button onClick={() => navigate("/home")} className="continue-button">
                  Continue Browsing
                </button>
              </div>
            ) : (
              <>
                <img className="waiting-logo" src="./assets/icon.png" alt="Logo" />
                <p className="waiting-title">We're evaluating your profile.</p>
                <p className="waiting-description">
                  In order to make sure our community holds up a standard, we don't allow any profiles to get in.
                </p>
                <button onClick={() => navigate("/home")} className="continue-button">
                  Continue Browsing
                </button>
              </>
            )}
          </div>
        )}
      </div>
      <Footer />
    </>
  );
};

export default WaitingScreen;