import React from 'react';
import ReactDOM from 'react-dom/client';
import '../node_modules/font-awesome/css/font-awesome.min.css';
import '../node_modules/bootstrap/dist/css/bootstrap.min.css';
import { BrowserRouter, Routes, Route } from 'react-router-dom';
import { Provider } from 'react-redux';
import store from './redux/store';
import { UserAuthContextProvider } from "./UserAuthContext";

import {
  Home,
  Product,
  Products,
  AboutPage,
  ContactPage,
  Cart,
  Login,
  Register,
  Checkout,
  PageNotFound,
  WaitingPage,
  CollectionPage,
  PrivacyPolicy,
  TermsAndConditions,
  CancellationRefundPolicy,
  Success,
  Phone,Seller,AddProduct,Buyer
} from "./pages";
import ProtectedRoute from './ProtectedRoute';  // Import the ProtectedRoute component

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <UserAuthContextProvider>
  <BrowserRouter>
    <Provider store={store}>
      <Routes>
        {/* Public Routes */}
        <Route path="/" element={<Login />} />
        <Route path="/register" element={<Register />} />

        {/* Protected Routes */}
        <Route
          path="/home"
          element={
            <ProtectedRoute>
              <Home />
            </ProtectedRoute>
          }
        />
        <Route
          path="/product"
          element={
            <ProtectedRoute>
              <Products />
            </ProtectedRoute>
          }
        />
        <Route
          path="/product/:id"
          element={
            <ProtectedRoute>
              <Product />
            </ProtectedRoute>
          }
        />
        <Route
          path="/about"
          element={
            <ProtectedRoute>
              <AboutPage />
            </ProtectedRoute>
          }
        />
        <Route
          path="/contact"
          element={
            <ProtectedRoute>
              <ContactPage />
            </ProtectedRoute>
          }
        />
        <Route
          path="/cart"
          element={
            <ProtectedRoute>
              <Cart />
            </ProtectedRoute>
          }
        />
        <Route
          path="/checkout"
          element={
            <ProtectedRoute>
              <Checkout />
            </ProtectedRoute>
          }
        />
        <Route
          path="/waiting"
          element={
            <ProtectedRoute>
              <WaitingPage />
            </ProtectedRoute>
          }
        />
         <Route
          path="/collection"
          element={
            <ProtectedRoute>
              <CollectionPage />
            </ProtectedRoute>
          }
        />
         <Route
          path="/success"
          element={
            <ProtectedRoute>
              <Success />
            </ProtectedRoute>
          }
        />
         <Route
          path="/privacy-policy"
          element={
            <ProtectedRoute>
              <PrivacyPolicy />
            </ProtectedRoute>
          }
        />
         <Route
          path="/terms-and-conditions"
          element={
            <ProtectedRoute>
              <TermsAndConditions />
            </ProtectedRoute>
          }
        />
         <Route
          path="/cancellation-refund-policy"
          element={
            <ProtectedRoute>
              <CancellationRefundPolicy />
            </ProtectedRoute>
          }
          />
         <Route
          path="/seller"
          element={
            <ProtectedRoute>
              <Seller />
              </ProtectedRoute>
          }
        />
         <Route
          path="/buyer"
          element={
            <ProtectedRoute>
              <Buyer />
              </ProtectedRoute>
          }
        />
         <Route
          path="/seller-add-product"
          element={
            <ProtectedRoute>
              <AddProduct />
              </ProtectedRoute>
          }
        />

        {/* Catch-All Route */}
        <Route path="*" element={<PageNotFound />} />
      </Routes>
    </Provider>
  </BrowserRouter>
  </UserAuthContextProvider>

);