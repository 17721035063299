import React from "react";
import "./css/TermsAndConditions.css"; // Import the CSS file for styling
import { Footer,Navbar } from "../components"; // Ensure this component is imported correctly

const TermsAndConditions = () => {
  return (
    <>
    <Navbar/>
    <div className="terms-and-conditions">
      <div className="container">
        <h1 className="mt-5">Terms & Conditions</h1>

        <section>
          <h2>Introduction</h2>
          <p>
            These Terms & Conditions govern your use of our website and the services we offer. By accessing or using our website, you agree to be bound by these Terms & Conditions.
          </p>
        </section>

        <section>
          <h2>User Accounts</h2>
          <p>
            When you create an account with us, you must provide accurate and complete information. You are responsible for safeguarding your account and for any activity that occurs under your account.
          </p>
        </section>

        <section>
          <h2>Prohibited Activities</h2>
          <p>
            You agree not to engage in any of the following prohibited activities:
          </p>
          <ul>
            <li>Copying, distributing, or disclosing any part of the website in any medium.</li>
            <li>Using automated systems, including robots, spiders, or scrapers, to access the website.</li>
            <li>Attempting to interfere with or compromise the integrity or security of the system.</li>
          </ul>
        </section>

        <section>
          <h2>Intellectual Property Rights</h2>
          <p>
            All content, trademarks, and data on this website, including but not limited to software, databases, text, graphics, icons, and hyperlinks, are the property of or licensed to our company. You agree not to reproduce, duplicate, copy, sell, or resell any portion of the website.
          </p>
        </section>

        <section>
          <h2>Limitation of Liability</h2>
          <p>
            Our company will not be held liable for any direct, indirect, incidental, special, or consequential damages arising from your use of or inability to use our website or services.
          </p>
        </section>

        <section>
          <h2>Changes to Terms & Conditions</h2>
          <p>
            We reserve the right to modify these Terms & Conditions at any time. Any changes will be effective immediately upon posting on the website. Your continued use of the website following the posting of changes constitutes your acceptance of such changes.
          </p>
        </section>

        <section>
          <h2>Contact Us</h2>
          <p>
            If you have any questions about these Terms & Conditions, please contact us at:
          </p>
          <p>Email: support@surplusdepot.com</p>
          <p>Phone: +91 9115111844</p>
        </section>
      </div>
    </div>
    <Footer />
    </>
  );
};

export default TermsAndConditions;