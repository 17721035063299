import React from "react";
import { NavLink } from "react-router-dom";
import "./css/Footer.css"; // Import the CSS file

const Footer = () => {
  return (
    <footer className="footer">
      <div className="container">
        <div className="row">
          {/* About Us Section */}
          <div className="col-md-3 col-sm-6">
            <h5>About Us</h5>
            <p className="text-muted">
              We are committed to providing the best products at unbeatable prices. Our team is dedicated to excellent customer service and satisfaction.
            </p>
          </div>

          {/* Quick Links Section */}
          <div className="col-md-3 col-sm-6">
            <h5>Quick Links</h5>
            <ul className="list-unstyled">
              <li>
                <NavLink to="/home" className="text-muted">Home</NavLink>
              </li>
              <li>
                <NavLink to="/product" className="text-muted">Products</NavLink>
              </li>
              <li>
                <NavLink to="/about" className="text-muted">About</NavLink>
              </li>
              <li>
                <NavLink to="/contact" className="text-muted">Contact</NavLink>
              </li>
            </ul>
          </div>

          {/* Policy Links Section */}
          <div className="col-md-3 col-sm-6">
            <h5>Our Policies</h5>
            <ul className="list-unstyled">
              <li>
                <NavLink to="/privacy-policy" className="text-muted">Privacy Policy</NavLink>
              </li>
              <li>
                <NavLink to="/terms-and-conditions" className="text-muted">Terms & Conditions</NavLink>
              </li>
              <li>
                <NavLink to="/cancellation-refund-policy" className="text-muted">Cancellation/Refund Policy</NavLink>
              </li>
            </ul>
          </div>

          {/* Contact Us Section */}
          <div className="col-md-3 col-sm-6">
            <h5>Contact Us</h5>
            <p className="text-muted">
              CBH TECH INDIA PRIVATE LIMITED <br />
              Office No. 727, 7TH Floor, Tower T3,<br />
              Amrapalli Leisure Valley Tech Zone IV,<br />
              Greater Noida West, Uttar Pradesh - 201308
            </p>
          </div>
        </div>

        {/* Social Media Section */}
        <div className="row mt-4">
          <div className="col-md-12 text-center">
            <h5>Follow Us</h5>
            <div className="social-icons">
              <a href="https://www.facebook.com" target="_blank" rel="noopener noreferrer">
                <i className="fab fa-facebook-f"></i>
              </a>
              <a href="https://www.twitter.com" target="_blank" rel="noopener noreferrer">
                <i className="fab fa-twitter"></i>
              </a>
              <a href="https://www.instagram.com" target="_blank" rel="noopener noreferrer">
                <i className="fab fa-instagram"></i>
              </a>
              <a href="https://www.linkedin.com" target="_blank" rel="noopener noreferrer">
                <i className="fab fa-linkedin"></i>
              </a>
            </div>
          </div>
        </div>

        {/* Copyright Section */}
        <div className="row">
          <div className="col-md-12 text-center mt-4">
            <p className="text-muted">&copy; 2024 Surplus Depot. All rights reserved.</p>
          </div>
        </div>
      </div>
    </footer>
  );
};

export default Footer;