import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom'; // Import useNavigate from React Router
import './css/categories.css'; // Import the CSS file

const Categories = ({ height = 100, width = 100 }) => {
  const navigate = useNavigate(); // Replace useNavigation with useNavigate
  const [data, setData] = useState([]);

  useEffect(() => {
    getCategories();
  }, []);

  const getCategories = async () => {
    try {
      const response = await fetch(`https://boxwood-spot-435312-q8.el.r.appspot.com/product/categories`);
      const categoriesData = await response.json();
      if (categoriesData?.response) {
        setData(categoriesData?.response);
      }
    } catch (error) {
      console.error('Error during getCategories:', error);
    }
  };

  const renderItem = (item,index) => (
    <div
      className="card-container"
      key={item.id}
      // style={{ height: `${height}px`, width: `${width}px` }}
      onClick={() => navigate('/collection', { state: { title: item.name } })} // Using navigate with state to pass title
    >
      <div className="card-container-inner" style={{marginLeft:index == 0 ?'20px':'0px'}}>
        <img
          src={item.image}
          alt={item.name}
          className="category-image"
          style={{ height: `${height}px`, width: `${width}px` }}
        />
      </div>
      <span className="category-name">{item.name}</span>
    </div>
  );

  return (
    <div className="categories-container-main">
      <h2 className="display-6 categories-header">Category</h2>
      <div className="categories-list">
        {data.map(renderItem)} {/* Replace FlatList with map */}
      </div>
    </div>
  );
};

export default Categories;