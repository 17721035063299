import { initializeApp } from "firebase/app";
import { getAuth } from "firebase/auth";

const firebaseConfig = {
    apiKey: "AIzaSyBzWL80jlfA8bgJ81JETwupXtHAKaoHJJQ",
    authDomain: "surplus-depot-8182d.firebaseapp.com",
    projectId: "surplus-depot-8182d",
    storageBucket: "surplus-depot-8182d.appspot.com",
    messagingSenderId: "517857255827",
    appId: "1:517857255827:web:88c720315b57b4e0354866"
  };
  
// Initialize Firebase
const app = initializeApp(firebaseConfig);
export const auth = getAuth(app);  
