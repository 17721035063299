import React, { useEffect, useState } from 'react';
import BidTimer from './BidTimer'; // Adjust the import path as necessary

const BidBox = ({ bidStartingPrice, bidEndTime, bids, isModal = false }) => {
  const [highestBid, setHighestBid] = useState(null);
  const [bidsDataList, setBidsDataList] = useState([]);

  useEffect(() => {
    console.log("bids");
    console.log(bids);
    if (bids && bids.length > 0) {
      let bidsData = bids;
      setBidsDataList(bidsData);
      const maxBid = bidsData.reduce(
        (max, bid) => (bid?.bidPrice > max?.bidPrice ? bid : max),
        bidsData[0]
      );
      setHighestBid(maxBid?.bidPrice);
    }
  }, [bids]);

  const containerStyle = {
    backgroundColor: '#FFE4D0',
    border: '0.7px solid #FF4107',
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
    margin: '0 auto',
    padding: '24px 20px 14px',
    borderRadius: '8px',
    position: 'relative',
    maxWidth: '90%',
    marginTop: isModal ? '20px' : '40px',
  };

  const dividerStyle = {
    marginRight: '10px',
    marginLeft: '20px',
    borderRight: '1px solid #1b2934',
    height: '30px',
  };

  const centerStyle = {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    marginLeft: '10px',
  };

  const upperTextStyle = {
    fontSize: '12px',
    marginBottom: '6px',
  };

  const lowerTextStyle = {
    fontSize: '16px',
    color: '#FF4107',
    fontWeight: '700',
  };

  const timerStyle = {
    position: 'absolute',
    top: '-15px',
    left: '0',
    right: '0',
    margin: '0 auto',
  };

  return (
    <div style={containerStyle}>
      {!isModal && (
        <div style={timerStyle}>
          <BidTimer bidEndTime={bidEndTime} />
        </div>
      )}
      <div style={centerStyle}>
        <span style={upperTextStyle}>Starting Bid</span>
        <span style={lowerTextStyle}>₹{bidStartingPrice}</span>
      </div>
      <div style={dividerStyle}></div>
      <div style={centerStyle}>
        <span style={upperTextStyle}>Latest Bid</span>
        <span style={lowerTextStyle}>₹{highestBid ?? bidStartingPrice}</span>
      </div>
      <div style={dividerStyle}></div>
      <div style={centerStyle}>
        <span style={upperTextStyle}>Bidders</span>
        <span style={lowerTextStyle}>{bidsDataList.length}</span>
      </div>
    </div>
  );
};

export default BidBox;