import React, { useState, useEffect, useRef } from 'react';
import { Footer } from "../components";
import { Link, useNavigate } from 'react-router-dom';
import './css/register.css'; // Import the CSS file for styles

const Register = () => {
  const [allCategories, setAllCategories] = useState([]);
  const [allSubCategories, setAllSubCategories] = useState([]);
  const [allThirdLevelCategories, setAllThirdLevelCategories] = useState([]);
  const [selectedCategory, setSelectedCategory] = useState('All');
  const [selectedSubCategory, setSelectedSubCategory] = useState('All');
  const [type, setType] = useState('Buyer');
  const [selectedThirdLevelCategory, setSelectedThirdLevelCategory] = useState('All');
  const [showCategoryInput, setShowCategoryInput] = useState(false);
  const [showSubCategoryInput, setShowSubCategoryInput] = useState(false);
  const [showThirdLevelCategoryInput, setShowThirdLevelCategoryInput] = useState(false);
  const [customCategory, setCustomCategory] = useState('');
  const [customSubCategory, setCustomSubCategory] = useState('');
  const [customThirdLevelCategory, setCustomThirdLevelCategory] = useState('');

  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [phone, setPhone] = useState("");
  const [usertypeList, setUsertypeList] = useState([
    { label: 'Buyer', value: '1' },
    { label: 'Seller', value: '2' },
    { label: 'Admin', value: '3' },
    { label: 'Buyer & Seller', value: '4' },
    { label: 'BuyerAdmin', value: '5' },
    { label: 'SellerAdmin', value: '6' },
  ]);
  const [snackbarVisible, setSnackbarVisible] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState("");
  const [invoiceData, setInvoiceData] = useState(null); // New state for invoice data

  const navigate = useNavigate();

  const validateEmail = (email) => {
    const re = /\S+@\S+\.\S+/;
    return re.test(email);
  };

  const validatePhone = (phone) => {
    const re = /^\d{10}$/; // Basic validation: 10 digits
    return re.test(phone);
  };

  const initiatePayment = async () => {
    try {
      const response = await fetch('https://boxwood-spot-435312-q8.el.r.appspot.com/user/create-Razorpay-Order', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json'
        },
        body: JSON.stringify({ amount: 500 }) // Set the amount or get it dynamically
      });

      const data = await response.json();
      if (data?.response) {
        const options = {
          theme: {
            color: '#FF4107',
            hide_topbar: true,
          },
          key: "rzp_test_0yObxKfiyArfMa", // Replace with your Razorpay key
          amount: data.response.amount, // in currency subunits
          currency: "INR",
          name: 'Surplus Depot',
          description: 'Stock Out Paisa In',
          order_id: data.response.id, // Pass the order ID received from the backend

          handler: async (response) => {
            try {
              // Handle registration after successful payment
              const payload = {
                email,
                name,
                password,
                phone,
                type,
                category: showCategoryInput ? customCategory : selectedCategory,
                subCategory: showSubCategoryInput ? customSubCategory : selectedSubCategory,
                thirdLevelCategory: showThirdLevelCategoryInput ? customThirdLevelCategory : selectedThirdLevelCategory,
              };

              const registerResponse = await fetch('https://boxwood-spot-435312-q8.el.r.appspot.com/user/insert', {
                method: 'POST',
                headers: {
                  'Content-Type': 'application/json',
                },
                body: JSON.stringify(payload),
              });

              const registerData = await registerResponse.json();
              if (registerData?.response) {
                localStorage.setItem("userData", JSON.stringify(registerData?.response[0]?.uuid));
                localStorage.setItem(
                  "userDataObject",
                  JSON.stringify({
                    name: registerData?.response[0]?.name,
                    xName: registerData?.response[0]?.xName,
                    email: registerData?.response[0]?.email,
                    phone: registerData?.response[0]?.phone,
                    access: registerData?.response[0]?.type,
                    status: registerData?.response[0]?.status,
                  })
                );
                // Set invoice data and show invoice
                setInvoiceData({
                  customerName: registerData?.response[0]?.name,
                  address: '123 Main Street, Anytown, AN 12345', // Adjust as needed
                  total: '500.00', // Adjust as needed
                  date: new Date().toLocaleDateString(),
                });
              }
              if (registerData?.response[0]?.status === "approved") {
                setSnackbarMessage("Registration Successful");
                setSnackbarVisible(true);
                navigate('/success', { state: { InvoiceData: {
                  customerName: registerData?.response[0]?.name,
                  address: '123 Main Street, Anytown, AN 12345', // Adjust as needed
                  total: '500.00', // Adjust as needed
                  date: new Date().toLocaleDateString(),
                  paymentReason:'Registration Fee'
                }, } })              } else {
                setSnackbarMessage("Registration Successful");
                setSnackbarVisible(true);
                navigate('/success', { state: { InvoiceData: {
                  customerName: registerData?.response[0]?.name,
                  address: '123 Main Street, Anytown, AN 12345', // Adjust as needed
                  total: '500.00', // Adjust as needed
                  date: new Date().toLocaleDateString(),
                  paymentReason:'Registration Fee'
                }, } })
              }
        } catch (error) {
          console.error("Error during registration:", error);
          setSnackbarMessage("Something went wrong during registration.");
          setSnackbarVisible(true);
        }
      },
      prefill: {
        name: name,
          email: email,
            contact: phone,
          },
      notes: {
        address: "Corporate Office",
          },
      theme: {
        color: "#3399cc",
          },
    };

    const rzp1 = new window.Razorpay(options);
    rzp1.open();
  } else {
    setSnackbarMessage("Failed to initiate payment. Please try again.");
  setSnackbarVisible(true);
}
    } catch (error) {
  console.error("Error initiating payment:", error);
  setSnackbarMessage("Something went wrong while initiating payment.");
  setSnackbarVisible(true);
}
  };

const getProductTags = async () => {
  try {
    const response = await fetch('https://boxwood-spot-435312-q8.el.r.appspot.com/user/category');
    const data = await response.json();

    const categories = data?.response?.uniqueCategoryNames?.map((item, index) => ({
      label: item,
      value: (index + 1).toString()
    }));

    if (categories) {
      setAllCategories([{ "label": "All", "value": "0" }, ...categories, { label: 'Other', value: 'other' }]);
    }

    const subcategories = data?.response?.uniqueSubcategoryNames?.map((item, index) => ({
      label: item,
      value: (index + 1).toString()
    }));

    if (subcategories) {
      setAllSubCategories([{ "label": "All", "value": "0" }, ...subcategories, { label: 'Other', value: 'other' }]);
    }

    const thirdLevelCategories = data?.response?.uniqueThirdLevelCategories?.map((item, index) => ({
      label: item,
      value: (index + 1).toString()
    }));

    if (thirdLevelCategories) {
      setAllThirdLevelCategories([{ "label": "All", "value": "0" }, ...thirdLevelCategories, { label: 'Other', value: 'other' }]);
    }

  } catch (error) {
    console.error('Error getting product tags:', error);
  }
};

const handleCategoryChange = (e) => {
  const value = e.target.value;
  setSelectedCategory(value);
  if (value === 'other') {
    setShowCategoryInput(true);
    setShowSubCategoryInput(true);
    setShowThirdLevelCategoryInput(true);
  } else {
    setShowCategoryInput(false);
    setSelectedSubCategory('');
    setSelectedThirdLevelCategory('');
    setShowSubCategoryInput(false);
    setShowThirdLevelCategoryInput(false);
  }
};

const handleSubCategoryChange = (e) => {
  const value = e.target.value;
  setSelectedSubCategory(value);
  if (value === 'other') {
    setShowSubCategoryInput(true);
    setShowThirdLevelCategoryInput(true);
  } else {
    setShowSubCategoryInput(false);
    setSelectedThirdLevelCategory('');
    setShowThirdLevelCategoryInput(false);
  }
};

const handleThirdLevelCategoryChange = (e) => {
  setSelectedThirdLevelCategory(e.target.value);
  if (e.target.value === 'other') {
    setShowThirdLevelCategoryInput(true);
  } else {
    setShowThirdLevelCategoryInput(false);
  }
};


useEffect(() => {
  getProductTags();
}, []);

return (
  <>
    <div className="register-container">
      {snackbarVisible && (
        <div className="snackbar">
          {snackbarMessage}
          <button onClick={() => setSnackbarVisible(false)}>Close</button>
        </div>
      )}
      <div className="register-content">
        <img className="register-logo" src="./assets/icon.png" alt="Logo" />
        <form onSubmit={(e) => { e.preventDefault(); initiatePayment(); }} className="register-form">
          <div className="input-group">
            <label>Name</label>
            <input
              type="text"
              value={name}
              onChange={(e) => setName(e.target.value)}
              className="register-input"
              placeholder="Enter Your Name"
            />
          </div>
          <div className="input-group">
            <label>Email</label>
            <input
              type="email"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
              className="register-input"
              placeholder="Enter Your Email"
            />
          </div>
          <div className="input-group">
            <label>Password</label>
            <input
              type="password"
              value={password}
              onChange={(e) => setPassword(e.target.value)}
              className="register-input"
              placeholder="Enter Your Password"
            />
          </div>
          <div className="input-group">
            <label>Phone</label>
            <input
              type="text"
              value={phone}
              onChange={(e) => setPhone(e.target.value)}
              className="register-input"
              placeholder="Enter Your Phone Number"
            />
          </div>
          <div className="input-group">
            <label>User Type</label>
            <select
              value={type}
              onChange={(e) => setType(e.target.value)}
              className="register-input"
            >
              {usertypeList.map((item) => (
                <option key={item.value} value={item.value}>{item.label}</option>
              ))}
            </select>
          </div>
          <div className="input-group">
            <label>Category</label>
            <select
              value={selectedCategory}
              onChange={handleCategoryChange}
              className="register-input"
            >
              {allCategories.map((cat) => (
                <option key={cat.value} value={cat.value}>{cat.label}</option>
              ))}
            </select>
            {showCategoryInput && (
              <input
                type="text"
                value={customCategory}
                onChange={(e) => setCustomCategory(e.target.value)}
                placeholder="Enter Custom Category"
                className="register-input"
              />
            )}
          </div>
          <div className="input-group">
            <label>Sub-Category</label>
            <select
              value={selectedSubCategory}
              onChange={handleSubCategoryChange}
              className="register-input"
            >
              {allSubCategories.map((subCat) => (
                <option key={subCat.value} value={subCat.value}>{subCat.label}</option>
              ))}
            </select>
            {showSubCategoryInput && (
              <input
                type="text"
                value={customSubCategory}
                onChange={(e) => setCustomSubCategory(e.target.value)}
                placeholder="Enter Custom Sub-Category"
                className="register-input"
              />
            )}
          </div>
          <div className="input-group">
            <label>Third Level Category</label>
            <select
              value={selectedThirdLevelCategory}
              onChange={handleThirdLevelCategoryChange}
              className="register-input"
            >
              {allThirdLevelCategories.map((thirdLevel) => (
                <option key={thirdLevel.value} value={thirdLevel.value}>{thirdLevel.label}</option>
              ))}
            </select>
            {showThirdLevelCategoryInput && (
              <input
                type="text"
                value={customThirdLevelCategory}
                onChange={(e) => setCustomThirdLevelCategory(e.target.value)}
                placeholder="Enter Custom Third Level Category"
                className="register-input"
              />
            )}
          </div>
          <button type="submit" className="register-button">Register</button>
        </form>

      </div>
    </div>

    <Footer />
  </>
);
};

export default Register;