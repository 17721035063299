import React from 'react';
import { Navigate } from 'react-router-dom';

// Assuming user data is stored in localStorage or Redux store
const isAuthenticated = () => {
  // Check if user is logged in (based on your login logic)
  // Example: check for a token or user object in localStorage
  return localStorage.getItem('userData');
};

const ProtectedRoute = ({ children }) => {
  if (!isAuthenticated()) {
    // Redirect to login page if not authenticated
    return <Navigate to="/" replace />;
  }

  // Render the protected component if authenticated
  return children;
};

export default ProtectedRoute;