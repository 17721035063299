import React, { useState, useEffect, useCallback } from 'react';
import { useNavigate } from 'react-router-dom'; // Assuming you are using React Router for navigation
import BidBox from '../components/productComponents/BidBox';
import BiddersList from '../components/productComponents/BiddersList';
import { Footer, Navbar } from "../components";

const SellerProfileScreen = () => {
    const navigate = useNavigate();
    const [loading, setLoading] = useState(false);
    const [selectedTab, setSelectedTab] = useState(0);
    const [currentSales, setCurrentSales] = useState([]);
    const [previousSales, setPreviousSales] = useState([]);
    const [expandedProductId, setExpandedProductId] = useState(null);
    const [userData, setUserData] = useState(null);
    const [isMobile, setIsMobile] = useState(window.innerWidth <= 768); // Default mobile check

    const fetchData = useCallback(async () => {
        setLoading(true);
        const userUUID = localStorage.getItem('userData') ? JSON.parse(localStorage.getItem('userData')) : false;
        try {
            const response = await fetch(`https://boxwood-spot-435312-q8.el.r.appspot.com/user/seller-page/${userUUID}`);
            const data = await response.json();
            setUserData(data?.response?.userData);
            setLoading(false);
            const currentDateTime = new Date();
            let current = [];
            let previous = [];
            let products = data?.response?.products;
            products.forEach(product => {
                const bidEndTime = new Date(product.bidEndTime);
                let productData = product;
                productData.image = JSON.parse(product.image ?? '[]');
                productData.bids = JSON.parse(product.bids ?? '{}');
                if (product?.bids && product?.bids?.length > 0) {
                    let bidsData = product?.bids;
                    const maxBid = bidsData?.reduce((max, bid) => bid?.bidPrice > max?.bidPrice ? bid : max, bidsData[0]);
                    product.heighestBid = maxBid;
                }
                if (bidEndTime > currentDateTime) {
                    current.push(productData);
                } else {
                    previous.push(productData);
                }
            });
            setCurrentSales(current);
            setPreviousSales(previous);
        } catch (error) {
            setLoading(false);
            console.error('Error fetching contact data:', error);
        }
    }, []);

    useEffect(() => {
        fetchData();
    }, [fetchData]);

    const LogoutFunction = async () => {
        localStorage.setItem('userData', null);
        localStorage.setItem('userDataObject', null);
        navigate('/login');
    };

    const handleTileClick = (productId) => {
        setExpandedProductId(expandedProductId === productId ? null : productId);
    };

    const renderSalesCard = (sale) => {
        const isExpanded = expandedProductId === sale.id;
        const bidEndTime = new Date(sale?.bidEndTime);
        const currentTime = new Date();
        const bidEndedData = bidEndTime < currentTime;

        return (
            <div
                key={sale.id}
                onClick={() => handleTileClick(sale.id)}
                style={{ 
                    marginBottom: '10px', 
                    padding: '10px', 
                    border: '1px solid #f5f5f5', 
                    borderRadius: '10px', 
                    boxShadow: '0px 2px 4px rgba(0, 0, 0, 0.3)', 
                    cursor: 'pointer',
                    backgroundColor: isExpanded ? '#f8f9fa' : '#fff',
                    transition: 'all 0.3s ease',
                    position: 'relative',
                    overflow: 'hidden'
                }}
            >
                <div style={{ display: 'flex', alignItems: 'center' }}>
                    <img src={sale.image[0]} alt="Product" style={{  width: isMobile ?'100px' :'200px',height:isMobile ?'100px' :'200px', borderRadius: '10px', marginRight: '20px' }} />
                    <div>
                        <h4>{sale.title}</h4>
                        <p>{sale.subtitle}</p>
                        <h5> ₹{sale?.heighestBid?.bidPrice ?? sale?.bidStartingPrice ?? ""}</h5>
                        <p style={{ color: sale?.status === 1 ? 'lightGreen' : 'red',fontWeight:'bold' }}>{sale?.status === 1 ? "Approved" : "Waiting for Approval"}</p>
                    </div>
                </div>
                {isExpanded && (
                    <div style={{ marginTop: '20px',marginBottom:'30px' }}>
                        <BidBox bidStartingPrice={sale?.bidStartingPrice} bidEndTime={sale?.bidEndTime} bids={sale?.bids} />
                        <div style={{ maxHeight: '330px', overflowY: 'auto', marginBottom: '20px' }}>
                            <BiddersList isBidsPage={true} bids={sale?.bids ?? []} />
                        </div>
                        {bidEndedData ? (
                            // <button style={{ boxShadow: '0 4px 6px rgba(0, 0, 0, 0.4)',backgroundColor: 'rgba(255, 22, 84, 1)', padding: '12px 20px', borderRadius: '50px', width: '9%', margin: '10px 0', color: '#fff' }}>
                            //     Bid Ended
                            // </button>
                            <></>
                        ) : (
                            <div style={{ display: 'flex', justifyContent: 'space-around', width: '90%', margin: '0 auto' }}>
                                <button style={{  boxShadow: '0 4px 6px rgba(0, 0, 0, 0.4)',backgroundColor: '#FF4107',border: '#FF4107', padding: '12px 20px', borderRadius: '50px', width: '45%', marginRight: '20px', color: '#fff' }}>
                                    Close Bid
                                </button>
                                <button style={{  boxShadow: '0 4px 6px rgba(0, 0, 0, 0.4)',backgroundColor: '#1B2934',border: '#1B2934', padding: '12px 20px', borderRadius: '50px', width: '45%', color: '#fff' }}>
                                    Archive
                                </button>
                            </div>
                        )}
                    </div>
                )}
            </div>
        );
    };

    return (
        <>
            <Navbar />

                <div className={"col-12"} style={{marginTop:'20px'}}>
                    <h2 className="display-5 text-center">Seller Profile</h2>
                    <hr />
                </div>
            {loading ? (
                <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: 'calc(100vh - 220px)' }}>
                    <div className="spinner-border" role="status">
                        <span className="sr-only">Loading...</span>
                    </div>
                </div>
            ) : (
                <div style={{ backgroundColor: '#fff', minHeight: '100vh' }}>
                    <div style={{ display: 'flex', justifyContent: 'space-between', margin: '10px 20px' }}>
                        <div>
                            <button onClick={() => setSelectedTab(0)} style={{marginBottom:'10px', backgroundColor: selectedTab === 0 ? '#FF4107' : '#E3E3E3', marginRight: '6px', padding: '10px', borderRadius: '6px', cursor: 'pointer', minWidth: '157px', border: selectedTab === 0 ? '#FF4107' : '#E3E3E3', boxShadow: selectedTab === 1 ? '0 2px 3px rgba(0, 0, 0, 0.4)' : '0 4px 6px rgba(0, 0, 0, 0.4)'}}>Active Products</button>
                            <button onClick={() => setSelectedTab(1)} style={{marginBottom:'10px',backgroundColor: selectedTab === 1 ? '#FF4107' : '#E3E3E3', padding: '10px', borderRadius: '6px', cursor: 'pointer', minWidth: '157px', border: selectedTab === 1 ? '#FF4107' : '#E3E3E3', boxShadow: selectedTab === 1 ? '0 4px 6px rgba(0, 0, 0, 0.4)' : '0 2px 3px rgba(0, 0, 0, 0.4)'}}>Previous Products</button>
                        </div>
                        <button style={{ backgroundColor: '#1B2934', color: '#fff', padding: '10px', borderRadius: '6px', cursor: 'pointer', boxShadow: '0 4px 6px rgba(0, 0, 0, 0.4)', border: '#1B2934' }} onClick={() => navigate('/seller-add-product')}>+ Add Product</button>
                    </div>

                    <div style={{ padding: '0 20px' }}>
                        {selectedTab === 0 ? currentSales.map(renderSalesCard) : previousSales.map(renderSalesCard)}
                    </div>
                </div>
            )}
            <Footer />
        </>
    );
};

export default SellerProfileScreen;