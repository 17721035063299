import React, { useEffect, useState, useCallback } from "react";
import Skeleton from "react-loading-skeleton";
import { Link, useParams } from "react-router-dom";
import Marquee from "react-fast-marquee";
import { useDispatch } from "react-redux";
import { addCart } from "../redux/action";
import { useNavigate } from 'react-router-dom';
import Slider from "react-slick"; // Import react-slick
import { Footer, Navbar, ProductCard } from "../components";
import BidBox from '../components/productComponents/BidBox'; // Add BidBox component
import BiddersList from '../components/productComponents/BiddersList'; // Add BiddersList component
import BidTimer from '../components/productComponents/BidTimer'; // Add BidTimer component
import './css/Product.css'; // Import the CSS file

// Slick settings
const sliderSettings = {
  dots: true,
  infinite: true,
  speed: 500,
  slidesToShow: 1,
  slidesToScroll: 1,
  autoplay: true,
  autoplaySpeed: 3000,
  pauseOnHover: true,
};

const Product = () => {
  const navigate = useNavigate();
  const { id } = useParams();
  const [product, setProduct] = useState({});
  const [similarProducts, setSimilarProducts] = useState([]);
  const [loading, setLoading] = useState(false);
  const [loading2, setLoading2] = useState(false);
  const [imageData, setimageData] = useState([]);
  const [bidValue, setBidValue] = useState(0);
  const [bidEndTime, setBidEndTime] = useState('');
  const [bidders, setBidders] = useState([]);
  const [loadingBidders, setLoadingBidders] = useState(false);
  const [isMobile, setIsMobile] = useState(window.innerWidth <= 768); // Default mobile check
  const [openModal, setOpenModal] = useState(false);
  const [highestBid, setHighestBid] = useState(null);
  const [showConfirmation, setShowConfirmation] = useState(false);
  const [confirmedBidValue, setConfirmedBidValue] = useState(0);
  const [loadingBid, setLoadingBid] = useState(false);
  const dispatch = useDispatch();

  const addProduct = (product) => {
    dispatch(addCart(product));
  };
  const toggleModal = () => {
    setOpenModal(!openModal);
  };
  const gotoPDP = (item) => {
    navigate(`/product/${item.uuid}`);
  };
  const handleIncrement = () => {
    setBidValue(prevBidValue => prevBidValue + 100);
  };

  const handleDecrement = () => {
    if (bidValue > highestBid) {
      setBidValue(prevBidValue => prevBidValue - 100);
    }
  };

  const handleEndEditing = () => {
    if (isNaN(bidValue) || bidValue < highestBid) {
      setBidValue(highestBid);
    }
  };
  const postDataToServer = async () => {
    try {
      setLoadingBid(true); // Start loading
      const userUUID = JSON.parse(localStorage.getItem('userData')); // Assuming you store userUUID in localStorage
      const postData = {
        productUUID: product.uuid,
        bidPrice: bidValue,
        userUUID: userUUID
      };

      const response = await fetch(`https://boxwood-spot-435312-q8.el.r.appspot.com/product/bids/insert`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(postData),
      });

      if (!response.ok) {
        throw new Error(`HTTP error! Status: ${response.status}`);
      }

      const data = await response.json();
      // Optionally update the bid list or show success message
      alert("Bid placed successfully!");
      setLoadingBid(false); // Start loading
      window.location.reload(); // Reload the page

    } catch (error) {
      console.error('Error placing bid:', error);
      setLoadingBid(false); // Start loading
    }
    finally {
      setLoadingBid(false); // Start loading
    }
  };
  useEffect(() => {
    const getProduct = async () => {
      setLoading(true);
      const response = await fetch(`https://boxwood-spot-435312-q8.el.r.appspot.com/product/${id}`);
      const data = await response.json();
      setProduct(data?.response || {});
      let biddersList = JSON.parse(data?.response?.bids ?? '[]');
      setBidders(biddersList);
      setBidValue(parseFloat(data?.response?.bidStartingPrice || 0));
      setBidEndTime(data?.response?.bidEndTime || '');
      setimageData(JSON.parse(data?.response?.image || '[]'));
      if (imageData.length < 3) {
        setimageData(prevData => [...prevData, prevData[0] || 'https://placehold.co/1000x1000', prevData[0] || 'https://placehold.co/1000x1000']);
      }
      setLoading(false);

      setLoading2(true);
      const response2 = await fetch(`https://boxwood-spot-435312-q8.el.r.appspot.com/product/category/${data?.response?.category}`);
      const data2 = await response2.json();
      setLoading2(false);
      setSimilarProducts(data2?.response || []);

      // Fetch bidders list
      // setLoadingBidders(true);
      // const response3 = await fetch(`https://boxwood-spot-435312-q8.el.r.appspot.com/product/bidders/${id}`);
      // const data3 = await response3.json();
      // setBidders(data3?.response || []);
      // setLoadingBidders(false);
    };
    getProduct();
  }, [id]);

  useEffect(() => {
    if (product?.bids && product?.bids?.length > 0) {
      const maxBid = bidders.reduce((max, bid) => bid.bidPrice > max ? bid : max, bidders[0]);
      setHighestBid(maxBid?.bidPrice ?? product?.bidStartingPrice);
      setBidValue(parseFloat(maxBid?.bidPrice ?? product?.bidStartingPrice) + 100);
    }
  }, [product, bidders]);

  const calculateTimeLeft = (endTime) => {
    const now = new Date();
    const endTimeDate = new Date(endTime);
    const difference = endTimeDate - now;
    let timeLeftString = '';

    if (difference > 0) {
      const hours = Math.floor((difference / (1000 * 60 * 60)) % 24);
      const minutes = Math.floor((difference / 1000 / 60) % 60);
      const seconds = Math.floor((difference / 1000) % 60);
      timeLeftString = `${String(hours).padStart(2, '0')}:${String(minutes).padStart(2, '0')}:${String(seconds).padStart(2, '0')}`;
    } else {
      timeLeftString = '00:00:00';
    }
    return timeLeftString;
  };

  const Loading = () => (
    <div className="container my-5 py-2">
      <div className="row">
        <div className="col-md-6 py-3">
          <Skeleton height={400} width={400} />
        </div>
        <div className="col-md-6 py-5">
          <Skeleton height={30} width={250} />
          <Skeleton height={90} />
          <Skeleton height={40} width={70} />
          <Skeleton height={50} width={110} />
          <Skeleton height={120} />
          <Skeleton height={40} width={110} inline={true} />
          <Skeleton className="mx-3" height={40} width={110} />
        </div>
      </div>
    </div>
  );

  const ShowProduct = () => (
    <div className="container">
      {openModal && (
        <div className="modal" onClick={toggleModal}>
          <div className="modal-content" onClick={e => e.stopPropagation()}>
            <h3 style={{ textAlign: 'center' }}>Place Your Bid</h3>
            <BidBox bidStartingPrice={product.bidStartingPrice} bidEndTime={bidEndTime} bids={bidders} bidValue={bidValue} setBidValue={setBidValue} />
            <div className="bid-input">
              <button onClick={handleDecrement}>-</button>
              <input
                type="number"
                className="inputModal1"
                value={bidValue}
                onChange={e => setBidValue(Number(e.target.value))}
                onBlur={handleEndEditing}
              />
              <button onClick={handleIncrement}>+</button>
            </div>
            <div className="buttonModal1" onClick={!loadingBid ? postDataToServer : null} style={{ position: 'relative', pointerEvents: loadingBid ? 'none' : 'auto' }}>
              {loadingBid && <div className="loader"></div>}
              {loadingBid ? "." :"Confirm Bid"}
            </div>
          </div>
        </div>
      )}
      <div className="row" style={{ justifyContent: 'center', alignItems: 'flex-start' }}>
        <div className={isMobile ? "" : "col-md-6 col-sm-12 py-3"} style={isMobile ? { maxWidth: '90%' } : {}}>

          <Slider {...sliderSettings}>
            {imageData.map((img, index) => (
              <div key={index}>
                <img
                  className="img-fluid"
                  style={{ objectFit: 'contain' }}
                  src={img}
                  alt={product.title}
                  width="100%"
                  height="400px"
                />
              </div>
            ))}
          </Slider>

        </div>
        <div className="col-md-6 py-5">
          <h4 className="text-uppercase text-muted">{product.category + ' - ' + product.subCategory + ' - ' + product.thirdLevelCategory}</h4>
          <h1 className="display-5">{product.title}</h1>
          <p className="lead">
            {product.rating && product.rating}{" "}
            <i className="fa fa-star"></i>
          </p>
          <h3 className="display-6 my-4"> <span style={{fontSize:'28px',color:'#888888',textDecoration:"line-through"}}>
          ₹{product.mrpPrice}
            </span> ₹{product.bidStartingPrice}</h3>
          <p className="lead">{product.description}</p>
          <BidBox bidStartingPrice={product.bidStartingPrice} bidEndTime={bidEndTime} bids={bidders} bidValue={bidValue} setBidValue={setBidValue} />
          <div style={{ height: '20px' }}></div>
          <div style={{ display: 'flex', justifyContent: 'space-around', width: '100%' }}>
            <button
              className="btn"
              style={{
                width: '48%',
                backgroundColor: '#1b2934',
                color: '#fff',
                boxShadow: '0 4px 6px rgba(0, 0, 0, 0.4)'
              }}
              onClick={() => addProduct(product)}
            >
              Add to Favourites
            </button>
            <button
              className="btn"
              style={{
                width: '48%',
                backgroundColor: '#FF4107',
                color: '#fff',
                boxShadow: '0 4px 6px rgba(0, 0, 0, 0.4)'
              }}
              onClick={toggleModal}
            >
              Place Bid
            </button>
          </div>
          <div style={{height:'350px',overflow:'auto',marginTop:'20px'}}>
          {<BiddersList bids={bidders} loading={loadingBidders} />}
          </div>

        </div>
      </div>
    </div>
  );

  const Loading2 = () => (
    <div className="my-4 py-4">
      <div className="d-flex">
        <div className="mx-4">
          <Skeleton height={400} width={250} />
        </div>
        <div className="mx-4">
          <Skeleton height={400} width={250} />
        </div>
        <div className="mx-4">
          <Skeleton height={400} width={250} />
        </div>
        <div className="mx-4">
          <Skeleton height={400} width={250} />
        </div>
      </div>
    </div>
  );

  const ShowSimilarProduct = () => (
    <div>
      <div className="d-flex">
        {similarProducts.map((item) => (
          <ProductCard key={item.id} product={item} gotoPDP={() => gotoPDP(item)} />
        ))}
      </div>
    </div>
  );

  return (
    <>
      <Navbar showBack={true} isPDP={true} />
      <div className="container">
        <div className="row">{loading ? <Loading /> : <ShowProduct />}</div>
        <div className="row">
          <div style={{ width: '100%', padding: 0 }}>
            <h2 style={{ marginTop: 20 }}>You may also Like</h2>
            <Marquee pauseOnHover={true} pauseOnClick={true} speed={50} >
              {loading2 ? <Loading2 /> : <ShowSimilarProduct />}
            </Marquee>
          </div>
        </div>
      </div>
      <Footer />
    </>
  );
};

export default Product;