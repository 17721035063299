import React, { useState, useEffect, useCallback } from 'react';
import { useNavigate } from 'react-router-dom';
import BidBox from '../components/productComponents/BidBox';
import BiddersList from '../components/productComponents/BiddersList';
import { Footer, Navbar } from "../components";

const BuyerProfileScreen = () => {
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const [selectedTab, setSelectedTab] = useState(0);
  const [currentSales, setCurrentSales] = useState([]);
  const [previousSales, setPreviousSales] = useState([]);
  const [expandedProductId, setExpandedProductId] = useState(null);
  const [userData, setUserData] = useState(null);
  const [isMobile, setIsMobile] = useState(window.innerWidth <= 768);

  const fetchData = useCallback(async () => {
    setLoading(true);
    const userUUID = localStorage.getItem('userData') ? JSON.parse(localStorage.getItem('userData')) : false;
    try {
      const response = await fetch(`https://boxwood-spot-435312-q8.el.r.appspot.com/user/buyer-page/${userUUID}`);
      const data = await response.json();
      setUserData(data?.response?.userData);
      const currentDateTime = new Date();
      let current = [];
      let previous = [];
      let products = data?.response?.products;
      products.forEach(product => {
        const bidEndTime = new Date(product.bidEndTime);
        let productData = product;
        productData.image = JSON.parse(product.image ?? '[]');
        productData.bids = JSON.parse(product.bids ?? '{}');
        if (bidEndTime > currentDateTime) {
          current.push(productData);
        } else {
          previous.push(productData);
        }
      });
      console.log(current?.length);
      console.log(previous?.length);
      setCurrentSales(current);
      setPreviousSales(previous);
    } catch (error) {
      console.error('Error fetching contact data:', error);
    } finally {
      setLoading(false);
    }
  }, []);

  useEffect(() => {
    fetchData();
  }, [fetchData]);

  const handleTabClick = (tabIndex) => setSelectedTab(tabIndex);

  const renderSalesCard = (sale) => (
    <div
      key={sale.uuid}
      onClick={() => navigate(`/product/${sale.uuid}`)}
      style={{
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        padding: '10px',
        margin: '10px 0',
        backgroundColor: '#fff',
        boxShadow: '0 2px 4px rgba(0, 0, 0, 0.1)',
        borderRadius: '10px',
        cursor: 'pointer',
      }}
    >
      <img
        src={sale.image[0]}
        alt={sale.title}
        style={{
          width: '80px',
          height: '80px',
          borderRadius: '10px',
          marginRight: '10px',
        }}
      />
      <div style={{ flex: 1 }}>
        <h3 style={{ margin: '0 0 5px', fontSize: '18px' }}>{sale.title}</h3>
        <p style={{ margin: '0', color: '#666' }}>{sale.subtitle}</p>
        <p style={{ fontWeight: 'bold', color: '#FF4107' }}>₹{sale.bidStartingPrice}</p>
      </div>
    </div>
  );

  return (
    <>
    <Navbar />
    <div style={{ padding: '20px',height:'100vh' }}>
      {loading ? (
        <div style={{
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          height: '100vh',
        }}>
          <div>Loading...</div>
        </div>
      ) : (
        <>
          <div style={{
            display: 'flex',
            justifyContent: 'center',
            marginBottom: '20px',
          }}>
            <button
              onClick={() => handleTabClick(0)}
              style={{
                backgroundColor: selectedTab === 0 ? '#FF4107' : '#E3E3E3',
                color: selectedTab === 0 ? '#fff' : '#000',
                padding: '10px 20px',
                border: 'none',
                borderRadius: '5px',
                cursor: 'pointer',
                marginRight: '10px',
              }}
            >
              Current Bids
            </button>
            <button
              onClick={() => handleTabClick(1)}
              style={{
                backgroundColor: selectedTab === 1 ? '#FF4107' : '#E3E3E3',
                color: selectedTab === 1 ? '#fff' : '#000',
                padding: '10px 20px',
                border: 'none',
                borderRadius: '5px',
                cursor: 'pointer',
              }}
            >
              Previous Bids
            </button>
          </div>

          {selectedTab === 0 && currentSales.length > 0 ? (
            < div style={{minHeight:"80vh"}}>
            {currentSales.map(renderSalesCard)}
            </div>
          ) : (
            selectedTab === 0 && <p style={{ textAlign: 'center', fontWeight: 'bold', color: '#FF4107',height:'80vh' }}>
              {'No Live Bids'}
            </p>
          )}
          {selectedTab === 1 && previousSales.length > 0 ? (
            < div style={{minHeight:"80vh"}}>
            {
            previousSales.map(renderSalesCard)}
</div>          ) : (
            selectedTab === 1 && <p style={{ textAlign: 'center', fontWeight: 'bold', color: '#FF4107',height:'80vh' }}>
              {'No Previous Bids'}
            </p>
          )}

          {expandedProductId && (
            <div style={{
              position: 'fixed',
              top: 0,
              left: 0,
              width: '100%',
              height: '100%',
              backgroundColor: 'rgba(0,0,0,0.5)',
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
            }}>
              <div style={{
                backgroundColor: '#fff',
                padding: '20px',
                borderRadius: '10px',
                width: isMobile ? '90%' : '50%',
              }}>
                <h3>Bid Details</h3>
                <BidBox bidEndTime={new Date()} bidStartingPrice={1000} bids={[]} />
                <BiddersList bids={[]} />
                <button
                  onClick={() => setExpandedProductId(null)}
                  style={{
                    marginTop: '20px',
                    padding: '10px 20px',
                    backgroundColor: '#FF4107',
                    color: '#fff',
                    border: 'none',
                    borderRadius: '5px',
                    cursor: 'pointer',
                  }}
                >
                  Close
                </button>
              </div>
            </div>
          )}
          <Footer/>
        </>
      )}
    </div>
    </>
  );
};

export default BuyerProfileScreen;