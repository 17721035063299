import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import Modal from 'react-modal';
import { Footer, Navbar, ProductCard } from "../components";
import axios from 'axios';
const AddProductForm = () => {
    const navigate = useNavigate();
    const [snackbarVisible, setSnackbarVisible] = useState(false);
    const [snackbarMessage, setSnackbarMessage] = useState('Product added successfully\nplease wait for admin to approve it');
    const [loading, setLoading] = useState(false);
    const [images, setImages] = useState([]);
    const [title, setTitle] = useState('');
    const [subtitle, setSubtitle] = useState('');
    const [description, setDescription] = useState('');
    const [weight, setWeight] = useState('');
    const [size, setSize] = useState('');
    const [sellAllTogether, setSellAllTogether] = useState(false);
    const [sizeType, setSizeType] = useState('');
    const [startingBid, setStartingBid] = useState('');
    const [bidTargetPrice, setTargetBid] = useState('');
    const [bidStartTime, setBidStartTime] = useState(new Date());
    const [rating, setRating] = useState('');
    const [category, setCategory] = useState('');
    const [subCategory, setSubCategory] = useState('');
    const [thirdLevelCategory, setThirdLevelCategory] = useState('');
    const [errors, setErrors] = useState({});
    const [minSellCount, setMinSellCount] = useState(0);
    const [costPerLot, setCostPerLot] = useState(0);
    const [mrp, setMrp] = useState('');  // MRP state

    const validateFields = () => {
        const newErrors = {};
        if (images.length < 3) newErrors.image = 'At least 3 images are required';
        if (!title) newErrors.title = 'Product Name is required';
        if (!description) newErrors.description = 'Product Detail is required';
        if (!weight) newErrors.weight = 'Weight is required';
        if (!sizeType) newErrors.size = 'Measurement Type is required';
        if (!size) newErrors.size = 'Size is required';
        if (!startingBid) newErrors.startingBid = 'Minimum Bid Price is required';
        if (isNaN(startingBid)) newErrors.startingBid = 'Minimum Bid Price must be a number';
        if (!rating) newErrors.rating = 'Rating is required';
        if (!category) newErrors.category = 'Category is required';
        if (!subCategory) newErrors.subCategory = 'SubCategory is required';
        if (!thirdLevelCategory) newErrors.thirdLevelCategory = 'Third Level Category is required';
        if (isNaN(mrp)) newErrors.mrp = 'MRP must be a number';  // MRP validation

        setErrors(newErrors);
        return Object.keys(newErrors).length === 0;
    };

    const handleImageSelection = async (e) => {
        const files = e.target.files;
        const selectedImages = Array.from(files).map(file => ({
            uri: URL.createObjectURL(file),
            name: file.name,
            type: file.type
        }));
        setImages(selectedImages);
    };

    const handleProductSubmission = async () => {
        if (!validateFields()) {
            console.log(errors, "Validation errors");
            return;
        }

        console.log("Submitting product");

        setLoading(true);
        const userUUID = localStorage.getItem('userData') ? JSON.parse(localStorage.getItem('userData')) : false;

        const formData = new FormData();
        formData.append('sellerUUID', userUUID);
        formData.append('title', title);
        formData.append('subtitle', subtitle);
        formData.append('description', description);
        formData.append('weight', weight);
        formData.append('size', size);
        formData.append('sizeType', sizeType);
        formData.append('rating', rating);
        formData.append('category', category);
        formData.append('subCategory', subCategory);
        formData.append('thirdLevelCategory', thirdLevelCategory);
        formData.append('bidStartingPrice', startingBid);
        formData.append('bidTargetPrice', bidTargetPrice);
        formData.append('bidStartTime', bidStartTime.toISOString());
        formData.append('sellAllTogether', sellAllTogether);
        formData.append('costPerLot', costPerLot);
        formData.append('minSellCount', minSellCount);
        formData.append('mrpPrice', mrp);  // Append MRP to form data

        // Convert image blobs to File objects and append them to FormData
        for (const [index, image] of images.entries()) {
            try {
                const response = await fetch(image.uri);
                const blob = await response.blob();
                const file = new File([blob], image.name || `image_${index}.jpg`, { type: image.type || 'image/jpeg' });
                formData.append('file', file);
            } catch (error) {
                console.error('Error converting blob to File:', error);
            }
        }
        console.log("formData");
        console.log(formData);
        try {
            const response = await axios.post('http://localhost:8080/product/insert2', formData, {
                headers: {
                    'Content-Type': 'multipart/form-data',
                },
            });
            if (response?.data?.response) {
                setSnackbarMessage(`${title} requested to be added. Please wait for admin to approve it.`);
                setSnackbarVisible(true);
                setTimeout(() => {
                    setSnackbarVisible(false);
                    navigate('/seller');
                }, 3000);
            }
            setLoading(false);
        } catch (error) {
            setLoading(false);
            console.error('Product submission failed:', error);
        }
    };

    const renderImagePreview = () => {
        return (
            <div style={{ display: 'flex', flexWrap: 'wrap', gap: '10px' }}>
                {images && images.map((image, index) => (
                    <img key={index} src={image.uri} alt="preview" style={{ width: '100px', height: '100px', objectFit: 'cover', borderRadius: '8px', border: '1px solid #ccc' }} />
                ))}
                <input type="file" multiple onChange={handleImageSelection} style={{ marginTop: '10px' }} />
            </div>
        );
    };

    const handleBidStartTimeChange = (e) => {
        const value = e.target.value;
        const newDate = new Date(value);

        // Check if the date is valid
        if (isNaN(newDate.getTime())) {
            console.log('Invalid date value');
            return;
        }

        setBidStartTime(newDate);
    };

    const inputStyle = (hasError) => ({
        borderColor: hasError ? 'red' : '#ccc',
        padding: '10px',
        borderRadius: '5px',
        marginBottom: '10px',
        width: '100%',
    });

    return (

        <>
            <Navbar showBack={true} isPDP={true} />
            <div style={{
                margin: "auto", padding: '20px', borderRadius: '10px', boxShadow: '0 4px 12px rgba(0, 0, 0, 0.1)', backgroundColor: '#fff'
            }}>
                {snackbarVisible && (
                    <Modal isOpen={snackbarVisible} onRequestClose={() => setSnackbarVisible(false)}>
                        <div style={{ marginTop:'30%',padding: '30px', textAlign: 'center', backgroundColor: '#FFE4D0'}}>
                            <h3>Success!</h3>
                            <p>{snackbarMessage}</p>
                        </div>
                    </Modal>
                )}
                <h2 style={{ textAlign: 'center', marginBottom: '20px' }}>Add Product</h2>

                <label style={{ fontWeight: 'bold' }}>Select Images (at least 3)</label>
                {renderImagePreview()}
                {errors.image && <p style={{ color: 'red' }}>{errors.image}</p>}

                <label style={{ fontWeight: 'bold' }}>Product Name</label>
                <input
                    type="text"
                    style={inputStyle(errors.title)}
                    placeholder="Enter Product Name"
                    value={title}
                    onChange={(e) => setTitle(e.target.value)}
                />
                {errors.title && <p style={{ color: 'red' }}>{errors.title}</p>}

                <label style={{ fontWeight: 'bold' }}>Subtitle</label>
                <input
                    type="text"
                    style={inputStyle(false)}
                    placeholder="Enter Product Subtitle"
                    value={subtitle}
                    onChange={(e) => setSubtitle(e.target.value)}
                />

                <label style={{ fontWeight: 'bold' }}>Product Detail</label>
                <input
                    type="text"
                    style={inputStyle(errors.description)}
                    placeholder="Enter Product Detail"
                    value={description}
                    onChange={(e) => setDescription(e.target.value)}
                />
                {errors.description && <p style={{ color: 'red' }}>{errors.description}</p>}
                <label style={{ fontWeight: 'bold' }}>Weight</label>
                <input
                   type="number"
                   min="0"
                    style={inputStyle(errors.weight)}
                    placeholder="Weight in Kg."
                    value={weight}
                    onChange={(e) => setWeight(e.target.value)}
                />
                {errors.weight && <p style={{ color: 'red' }}>{errors.weight}</p>}
                <div style={{ display: 'flex', justifyContent: 'space-between', gap: '10px' }}>
                    <div style={{ flex: 1 }}>
                        <label style={{ fontWeight: 'bold' }}>Measurement Type</label>
                        <input
                            type="text"
                            style={inputStyle(errors.sizeType)}
                            placeholder="ex. - color/size/quantity/liters..."
                            value={sizeType}
                            onChange={(e) => setSizeType(e.target.value)}
                        />
                        {errors.sizeType && <p style={{ color: 'red' }}>{errors.sizeType}</p>}
                    </div>

                    <div style={{ flex: 1 }}>
                        <label style={{ fontWeight: 'bold' }}>Measurement Value</label>
                        <input
                            type="text"
                            style={inputStyle(errors.size)}
                            placeholder="ex. Red / L / 10 / 1...."
                            value={size}
                            onChange={(e) => setSize(e.target.value)}
                        />
                        {errors.size && <p style={{ color: 'red' }}>{errors.size}</p>}
                    </div>
                </div>
                <div>

                <input
                    type="checkbox"
                    checked={sellAllTogether}

                    onChange={(e) => setSellAllTogether(e.target.checked)}
                    />
                <label style={{ fontWeight: 'bold', marginLeft:'10px' }}>Do you want to sell all together?</label>
                    </div>
                    {!sellAllTogether && (
                         <div style={{ display: 'flex', justifyContent: 'space-between', gap: '10px' }}>
                    <div style={{ flex: 1 }}>
                    <label style={{ fontWeight: 'bold' }}>Minimum Lot Size</label>
                        <input
                            type="number"
                            min="0"
                            style={inputStyle(errors.minSellCount)}
                            placeholder="Enter Minimum Lot Size"
                            value={minSellCount}
                            onChange={(e) => setMinSellCount(e.target.value)}
                        />
                        {errors.minSellCount && <p style={{ color: 'red' }}>{errors.minSellCount}</p>}

                    </div>

                    <div style={{ flex: 1 }}>
                    <label style={{ fontWeight: 'bold' }}>Cost Per Lot</label>
                        <input
                           type="number"
                           min="0"
                            style={inputStyle(errors.costPerLot)}
                            placeholder="Enter Cost Per Lot"
                            value={costPerLot}
                            onChange={(e) => setCostPerLot(e.target.value)}
                        />
                        {errors.costPerLot && <p style={{ color: 'red' }}>{errors.costPerLot}</p>}
                    </div>
                </div>
                    )}
                  <div style={{ display: 'flex', justifyContent: 'space-between', gap: '10px' }}>
                    <div style={{ flex: 1 }}>
                        <label style={{ fontWeight: 'bold' }}>Starting Bid Price</label>
                        <input
                            type="number"
                            min="0"
                            style={inputStyle(errors.startingBid)}
                            placeholder="Enter Starting Bid Price"
                            value={startingBid}
                            onChange={(e) => setStartingBid(e.target.value)}
                        />
                        {errors.startingBid && <p style={{ color: 'red' }}>{errors.startingBid}</p>}
                    </div>

                    <div style={{ flex: 1 }}>
                        <label style={{ fontWeight: 'bold' }}>MRP</label>
                        <input
                            type="number"
                            min="0"
                            style={inputStyle(errors.mrp)}
                            placeholder="Enter MRP"
                            value={mrp}
                            onChange={(e) => setMrp(e.target.value)}
                        />
                        {errors.mrp && <p style={{ color: 'red' }}>{errors.mrp}</p>}
                    </div>
                </div>
                <label style={{ fontWeight: 'bold' }}>Target Bid Price</label>
                <input
                    ttype="number"
                    min="0"
                    style={inputStyle(false)}
                    placeholder="Enter Target Bid Price"
                    value={bidTargetPrice}
                    onChange={(e) => setTargetBid(e.target.value)}
                />

                <label style={{ fontWeight: 'bold' }}>Bid End Time</label>
                <input
                    type="datetime-local"
                    style={inputStyle(false)}
                    value={bidStartTime.toISOString().substring(0, 16)}
                    onChange={handleBidStartTimeChange}
                />

                <label style={{ fontWeight: 'bold' }}>Rating</label>
                <input
                    type="text"
                    style={inputStyle(errors.rating)}
                    placeholder="Enter Rating"
                    value={rating}
                    onChange={(e) => setRating(e.target.value)}
                />
                {errors.rating && <p style={{ color: 'red' }}>{errors.rating}</p>}

                <label style={{ fontWeight: 'bold' }}>Category</label>
                <input
                    type="text"
                    style={inputStyle(errors.category)}
                    placeholder="Enter Category"
                    value={category}
                    onChange={(e) => setCategory(e.target.value)}
                />
                {errors.category && <p style={{ color: 'red' }}>{errors.category}</p>}

                <label style={{ fontWeight: 'bold' }}>Sub Category</label>
                <input
                    type="text"
                    style={inputStyle(errors.subCategory)}
                    placeholder="Enter Sub Category"
                    value={subCategory}
                    onChange={(e) => setSubCategory(e.target.value)}
                />
                {errors.subCategory && <p style={{ color: 'red' }}>{errors.subCategory}</p>}

                <label style={{ fontWeight: 'bold' }}>Third Level Category</label>
                <input
                    type="text"
                    style={inputStyle(errors.thirdLevelCategory)}
                    placeholder="Enter Third Level Category"
                    value={thirdLevelCategory}
                    onChange={(e) => setThirdLevelCategory(e.target.value)}
                />
                {errors.thirdLevelCategory && <p style={{ color: 'red' }}>{errors.thirdLevelCategory}</p>}

                <button
                    onClick={handleProductSubmission}
                    disabled={loading}
                    style={{
                        width: '100%',
                        padding: '20px',
                        border:'#FF4107',
                        backgroundColor: '#FF4107',
                        boxShadow: '0 4px 6px rgba(0, 0, 0, 0.4)',
                        color: '#fff',
                        fontSize: '16px',
                        fontWeight: 'bold',
                        borderRadius: '5px',
                        cursor: loading ? 'not-allowed' : 'pointer',
                        marginTop: '20px',
                    }}
                >
                    {loading ? 'Adding Product...' : 'Add Product'}
                </button>
            </div>
            <Footer />
        </>
    );
};

export default AddProductForm;