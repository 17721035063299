import React, { useEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import './css/collectionPage.css'; // Import the CSS file
import { ProductCard } from "../components";
import { Footer, Navbar } from "../components";

const CollectionPage = ({ showTitle = true, title: propTitle }) => {
  const location = useLocation(); // Get the location from React Router
  const navigate = useNavigate(); // Use useNavigate from React Router
  const [title, setTitle] = useState(location.state?.title || propTitle || ""); // Use location state for title
  const [products, setProducts] = useState([]);

  useEffect(() => {
    setTitle(location.state?.title || propTitle || "");
  }, [location.state?.title, propTitle]);

  useEffect(() => {
    if (title) getProductByCategories();
  }, [title]);

  const gotoPDP = (item) => {
    console.log({ itemData: item.uuid });
    navigate(`/product/${item.uuid}`);
};

  const getProductByCategories = async () => {
    try {
      const response = await fetch(`https://boxwood-spot-435312-q8.el.r.appspot.com/product/collection/${title}`);
      const productsData = await response.json();
      if (productsData?.response) {
        setProducts(productsData.response);
      } else {
        setProducts([]);
      }
    } catch (error) {
      console.error("Error during getCategories:", error);
    }
  };

  const renderHeader = () => {
    if (!showTitle) return null;
    return <h2 className="collection-title display-5 text-center">{title}</h2>;
  };

  return (
    <>
    <Navbar showBack={true}/>
    <div className="collection-container">
      {renderHeader()}
      <div className="collection-list">
        {products.length > 0 ? (
            products.map((item) => (
                <ProductCard key={item.id} product={item} gotoPDP={() => gotoPDP(item)} />
                ))
                ) : (
                    <p className="collection-empty-text">No Products Available</p>
                    )}
      </div>
    </div>
    <Footer />

                    </>
  );
};

export default CollectionPage;