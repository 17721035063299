import React, { useState, useEffect } from "react";
import { useDispatch } from "react-redux";
import { addCart } from "../redux/action";
import Skeleton from "react-loading-skeleton";
import "react-loading-skeleton/dist/skeleton.css";
import { useNavigate } from 'react-router-dom';
import { ProductCard } from "../components";

const Products = () => {
  const navigate = useNavigate(); // Use useNavigate from React Router
  const [data, setData] = useState([]);
  const [filter, setFilter] = useState(data);
  const [loading, setLoading] = useState(false);
  const [allCategories, setAllCategories] = useState([]);
  const [allTitles, setAllTitles] = useState([]);  // State to hold all titles for search suggestions
  const [suggestions, setSuggestions] = useState([]);  // State to hold filtered suggestions
  const [searchQuery, setSearchQuery] = useState('');  // State to hold search input value
  let componentMounted = true;

  const dispatch = useDispatch();

  const addProduct = (product) => {
    dispatch(addCart(product));
  };

  const gotoPDP = (item) => {
    console.log({ itemData: item.uuid });
    navigate(`/product/${item.uuid}`);
  };

  useEffect(() => {
    const getProducts = async () => {
      setLoading(true);
      const response = await fetch("https://boxwood-spot-435312-q8.el.r.appspot.com/product/all");
      if (componentMounted) {
        let ddata = await response.clone().json();
        console.log("ddata");
        console.log(ddata?.response);
        setData(ddata?.response);
        setFilter(ddata?.response);
        setLoading(false);
      }
      return () => {
        componentMounted = false;
      };
    };

    const getProductTags = async () => {
      let uuid = await localStorage.getItem('userData');
      uuid = uuid.replace('"', "").replace('"', "");
      console.log("uuid", uuid);
      try {
        const response = await fetch(`https://boxwood-spot-435312-q8.el.r.appspot.com/product/user-category/${uuid}`);
        const data = await response.json();
        console.log("data");
        console.log(data);
        setAllCategories(data?.response);
      } catch (error) {
        console.error('SignUpScreen Error getting getproductTags:', error);
      }
    };

    const fetchSuggestions = async () => {
      try {
        const response = await fetch(`https://boxwood-spot-435312-q8.el.r.appspot.com/product/search`);
        const data = await response.json();
        setAllTitles(data?.response);
      } catch (error) {
        console.error('Error fetching suggestions:', error);
      }
    };

    getProducts();
    getProductTags();
    fetchSuggestions();
  }, []);

  const handleSearchChange = (text) => {
    setSearchQuery(text);
    if (text.length > 0) {
      const filteredSuggestions = allTitles.filter(title =>
        title.toLowerCase().includes(text.toLowerCase())
      );
      setSuggestions(filteredSuggestions);
    } else {
      setSuggestions([]);
    }
  };

  const handleSuggestionClick = (suggestion) => {
    setSearchQuery(suggestion);
    setSuggestions([]);
    navigate('/collection', { state: { title: suggestion } });  // Navigate to Collection page
  };

  const Loading = () => {
    return (
      <>
        <div className="col-12 py-5 text-center">
          <Skeleton height={40} width={560} />
        </div>
        <div className="col-md-4 col-sm-6 col-xs-8 col-12 mb-4">
          <Skeleton height={592} />
        </div>
        {/* Repeat skeleton cards as needed */}
      </>
    );
  };

  const filterProduct = (cat) => {
    const updatedList = data.filter((item) =>
      item.category.includes(cat) ||
      item.subCategory.includes(cat) ||
      item.thirdLevelCategory.includes(cat) ||
      item.title.includes(cat)
    );
    setFilter(updatedList);
  };

  const ShowProducts = () => {
    return (
      <>
        <div className="buttons text-center py-5">
          <button className="btn btn-outline-dark btn-sm m-2" onClick={() => setFilter(data)}>All</button>
          {allCategories.map((product) => (
            <button key={product} className="btn btn-outline-dark btn-sm m-2" onClick={() => filterProduct(product)}>{product}</button>
          ))}
        </div>
        {filter.length === 0 && <div className="display-6 text-center" style={{ marginTop: 100, marginBottom: 100 }}>
          No Products in this category yet!
        </div>}
        {filter.map((product) => (
          <div id={product.id} key={product.id} className="col-md-4 col-sm-6 col-xs-8 col-12 mb-4">
            <ProductCard key={product.id} product={product} gotoPDP={() => gotoPDP(product)} />
          </div>
        ))}
      </>
    );
  };

  return (
    <>
      <div className="container my-3 py-3">
        <div className="row">
          <div className="col-12">
            <h2 className="display-5 text-center">Latest Products</h2>
            <hr />
          </div>
        </div>

        {/* Search Bar */}
        <div className="row justify-content-center mb-4">
          <div className="col-md-6">
            <input
              type="text"
              className="form-control"
              placeholder="Search products..."
              value={searchQuery}
              onChange={(e) => handleSearchChange(e.target.value)}
            />
            {suggestions.length > 0 && (
              <ul className="list-group mt-2">
                {suggestions.map((suggestion, index) => (
                  <li key={index} className="list-group-item" onClick={() => handleSuggestionClick(suggestion)}>
                    {suggestion}
                  </li>
                ))}
              </ul>
            )}
          </div>
        </div>

        <div className="row justify-content-center">
          {loading ? <Loading /> : <ShowProducts />}
        </div>
      </div>
    </>
  );
};

export default Products;