import React, { useEffect, useState } from 'react';
import { format } from 'date-fns';

const BiddersList = ({ isExtend = false, bids, isBidsPage = false }) => {
  const [bidsDataList, setBidsDataList] = useState([]);
  const [userUUID, setUserUUID] = useState('');

  useEffect(() => {
    if (bids && bids.length > 0) {
      let bidsData = bids;
      bidsData.sort((a, b) => b.bidPrice - a.bidPrice);
      setBidsDataList(bidsData);
    }
  }, [bids]);

  // Function to handle user data check
  const checkUserData = () => {
    const userData = localStorage.getItem('userData');
    setUserUUID(userData ? JSON.parse(userData) : '');
  };

  // Trigger checkUserData on component mount
  useEffect(() => {
    checkUserData();
  }, []);

  const formatBidTime = (bidTime) => {
    return format(new Date(bidTime), 'h:mma, dd/MM/yyyy');
  };

  // Inline styles
  const containerStyle = {
    marginTop: '40px',
    backgroundColor: '#F3F3F3',
    minWidth: '90%',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    padding: '20px',
    flex: 1,
    borderTopLeftRadius: isBidsPage ? '10px' : '4px',
    borderTopRightRadius: isBidsPage ? '10px' : '4px',
    borderBottomLeftRadius: isBidsPage ? '10px' : '0',
    borderBottomRightRadius: isBidsPage ? '10px' : '0',
  };

  const headerTextStyle = {
    fontSize: '18px',
    fontWeight: '700',
    width: '100%',
    marginBottom: '20px',
    textAlign: 'center',
    color: '#000'
  };

  const bidderTimeStyle = {
    fontSize: '12px',
    color: '#B6B6B6'
  };

  const bidderViewStyle = (index) => ({
    width: '95%',
    margin: 'auto',
    backgroundColor: index === 0 ? '#395060' : '#FFF',
    padding: '8px',
    borderRadius: '8px',
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
    marginBottom: '10px'
  });

  const rowStyle = {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center'
  };

  const imageContainerStyle = {
    width: '55px',
    height: '55px',
    borderRadius: '50%',
    backgroundColor: '#f7f8fd',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    marginRight: '15px'
  };

  const imageStyle = {
    width: '50%',
    height: '50%',
    objectFit: 'contain'
  };

  const bidderPriceStyle = (index) => ({
    marginRight: '10px',
    fontSize: '18px',
    fontWeight: '700',
    color: index === 0 ? '#fff' : '#000'
  });

  return (
    <div style={{ ...containerStyle, paddingBottom: isExtend ? '60px' : '20px' }}>
      <div style={headerTextStyle}>Bidders</div>
      {bidsDataList.length > 0 ? bidsDataList.map((bidder, index) => (
        <div style={bidderViewStyle(index)} key={`${bidder.bidPrice}-${bidder.userUUID}-${bidder.bidTime}`}>
          <div style={rowStyle}>
            <div style={imageContainerStyle}>
              <img src="https://cdn.shopify.com/s/files/1/0565/8021/0861/files/image_12_Traced_1.png?v=1683197265" style={imageStyle} alt="Bidder" />
            </div>
            <div>
              <div style={{
                fontSize: '16px',
                fontWeight: '600',
                color: index == 0 ? "#fff": '#1b2934'
              }}>
                {bidder.userUUID === userUUID ? 'ME' : bidder.xName}
              </div>
              <div style={bidderTimeStyle}>
                {formatBidTime(bidder.bidTime)}
              </div>
            </div>
          </div>
          <div style={bidderPriceStyle(index)}>
            ₹{bidder.bidPrice}
          </div>
        </div>
      )) : (
        <div style={{ ...{
          fontSize: '16px',
          fontWeight: '600',
          color: '#1b2934'
        }, marginTop: '20px', marginBottom: '40px', width: '100%', textAlign: 'center' }}>
          There haven't been any bidders yet.
        </div>
      )}
    </div>
  );
};

export default BiddersList;