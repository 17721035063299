import React, { useEffect, useState } from 'react';
import './css/productCard.css'; // Import the CSS file

const ProductCard = ({ product, gotoPDP }) => {
  const [imageData, setImageData] = useState(['https://placehold.co/1000x1000']);

  useEffect(() => {
    if (product?.image) {
      setImageData(JSON.parse(product?.image));
    }
  }, [product]);

  return (
    <div className="product-card" onClick={gotoPDP}>
      <img src={imageData[0]} alt={product.title} className="product-image" />
      <div className="product-title">{product.title}</div>
      <div className="product-description">{product.description}</div>
      <div className="product-price" style={{color:'#000',fontWeight:'bold'}}>
        <span style={{textDecoration:'line-through',color:'#888888',fontWeight:'300'}}>
        ₹{product.mrpPrice}
        </span>
        {" "}₹{product.bidStartingPrice}
        </div>
      <div className="product-button">
        <span className="product-button-text">View Details</span>
      </div>
    </div>
  );
};

export default ProductCard;