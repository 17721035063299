import React from 'react'
import { NavLink, useNavigate } from 'react-router-dom'
import { useSelector } from 'react-redux'
import './css/Navbar.css'; // Import the CSS file

const Navbar = ({ showBack = false,isPDP=false }) => {
    const navigate = useNavigate(); // Hook for navigation
    const state = useSelector(state => state.handleCart);

    // Handler to go back
    const handleBack = () => {
        navigate(-1); // Go back one step in history
    };

    return (
        <nav className="navbar navbar-expand-lg navbar-light bg-light py-3 sticky-top">
            <div className="container">
                {/* Conditionally render back button */}
                {showBack && (
                    <button className="btn btn-light me-2" onClick={handleBack}>
                        <i className="fa fa-arrow-left"></i> {/* Font Awesome back icon */}
                    </button>
                )}
                <NavLink className="navbar-brand fw-bold fs-4 px-2" to="/home">
                    <img
                        className="nav-img"
                        src={isPDP ?"../assets/icon.png" : "./assets/icon.png"}
                        alt="logo"
                        height={70}
                    />
                </NavLink>
                <button
          className="navbar-toggler mx-2 custom-toggler" // Updated class
          type="button"
          data-toggle="collapse"
          data-target="#navbarSupportedContent"
          aria-controls="navbarSupportedContent"
          aria-expanded="false"
          aria-label="Toggle navigation"
        >
          <i className="fas fa-bars custom-menu-icon"></i> {/* Font Awesome "bars" icon */}
        </button>

                <div className="collapse navbar-collapse" id="navbarSupportedContent">
                    <ul className="navbar-nav m-auto my-2 text-center">
                        <li className="nav-item">
                            <NavLink className="nav-link" to="/home">Home</NavLink>
                        </li>
                        <li className="nav-item">
                            <NavLink className="nav-link" to="/product">Products</NavLink>
                        </li>
                        <li className="nav-item">
                            <NavLink className="nav-link" to="/seller">Sell</NavLink>
                        </li>
                        <li className="nav-item">
                            <NavLink className="nav-link" to="/buyer">My Bids</NavLink>
                        </li>
                        <li className="nav-item">
                            <NavLink className="nav-link" to="/about">About</NavLink>
                        </li>
                        <li className="nav-item">
                            <NavLink className="nav-link" to="/contact">Contact</NavLink>
                        </li>
                    </ul>
                    <div className="buttons text-center">
                        <NavLink to="/cart" className="btn btn-outline-dark m-2">
                            <i className="fa fa-cart-shopping mr-1"></i> Cart ({state.length})
                        </NavLink>
                    </div>
                </div>
            </div>
        </nav>
    );
}

export default Navbar;